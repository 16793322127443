// deps
import { Avatar, HStack, Text } from "@chakra-ui/react";

// libraries
import generatePampaUri from "@raiden/library-ui/libraries/utils/generatePampaUri";

// helpers
import { usersGetNicename } from "@raiden/library-ui/helpers/users";
import getSizes from "@raiden/library-ui/helpers/avatar/getSizes";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Admin").Admin | import("@raiden/library-ui/types/Customer").Customer} user
 * @property {import("@raiden/library-ui/types/common/Avatar").AvatarSize} [size]
 * @property {boolean} [shouldRenderDisplayName]
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */

/**
 * @param {Props} props
 */
export default function UsersAvatar(props) {
  const { user, size, shouldRenderDisplayName = true } = props;

  const { width, height } = getSizes({ size });

  const nicename = usersGetNicename({ resource: user });

  return (
    <HStack spacing="1rem">
      <Avatar
        size={size}
        src={
          user?.avatar
            ? generatePampaUri({
                image: user.avatar,
                format: "default",
                mode: "crop",
                width,
                height,
              })
            : undefined
        }
        name={nicename}
      />

      {shouldRenderDisplayName && <Text>{nicename}</Text>}
    </HStack>
  );
}
