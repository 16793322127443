// deps
import { endOfMonth, getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";

// components
import PropTypes from "prop-types";
import CalendarCell from "./CalendarCell";

/**
 * @typedef Props
 * @property {import("@react-stately/calendar").CalendarState | import("@react-stately/calendar").RangeCalendarState} state
 * @property {object} offset
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} [setOpen]
 * @property {boolean} [shouldCloseOnDaySelect]
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function CalendarGrid({ state, offset = {}, setOpen, shouldCloseOnDaySelect }) {
  let { locale } = useLocale();
  let startDate = state.visibleRange.start.add(offset);
  let endDate = endOfMonth(startDate);
  let { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = Math.max(
    getWeeksInMonth(state.visibleRange.start, locale),
    getWeeksInMonth(state.visibleRange.end, locale),
  );

  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                    setOpen={setOpen}
                    shouldCloseOnDaySelect={shouldCloseOnDaySelect}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CalendarGrid;

CalendarGrid.propTypes = {
  state: PropTypes.object,
  offset: PropTypes.object,
  setOpen: PropTypes.func,
  shouldCloseOnDaySelect: PropTypes.bool,
};
