// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "../../../constants/users";

/**
 * Retourne vrai si l’utilisateur courant est l’utilisateur racine.
 * @param {object} params
 * @param {import("../../../types/User").UserBase} [params.user]
 */
export default function usersIsRoot({ user }) {
  return 1 === user?.id && USERS_USER_TYPE_VALUE_ADMIN === user?.user_type;
}
