// deps
import {
  Box,
  DarkMode,
  Divider,
  Flex,
  HStack,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  Portal,
  Spinner,
  useColorMode,
} from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

// components
import UsersAvatar from "../../../components/Users/Avatar";

// hooks
import { useDefaultEnvironments } from "../../../contexts/DefaultEnvironments";
import { useScrollBlocker } from "../../../hooks/useScrollBlocker";

// containers
import UserMenu from "../../UserMenu";
import MenuItem, { isActivePath } from "./MenuItem";

// constants
import EnvironmentSelect from "../../../components/EnvironmentSelectWithoutAuthorizations";
import { WIDTH_MENU } from "../../../constants/theme";
import usePreviousColorMode from "@raiden/library-ui/contexts/usePreviousColorMode";
import ThemeSwitch from "../ThemeSwitch";

/**
 * Trim menu items & add unique id
 * @param {import("./MenuItem").Item[]} menuItems
 * @param {{
 * canPerform: (params: { authorizations: string | string[]}) => boolean,
 * isRoot: boolean
 * }} param1
 * @param {number} [depth]
 * @returns {import("./MenuItem").Item[]}
 */
export function prepare(menuItems = [], { canPerform, isRoot }, depth = 0) {
  const trimmed = [];
  menuItems?.forEach((menuItem) => {
    const trimmedChildren = prepare(menuItem.children, { canPerform, isRoot });
    if (
      (menuItem?.validator?.({ canPerform, isRoot }) ?? true) &&
      ((menuItem?.href ?? []).length > 0 ||
        typeof menuItem?.onClick === "function" ||
        trimmedChildren.length > 0)
    ) {
      trimmed.push({
        ...menuItem,
        children: prepare(menuItem.children, { canPerform, isRoot }, depth + 1),
      });
    }
  });
  return trimmed;
}

/**
 * @param {object} options
 * @param {import("next/router").NextRouter} options.router
 * @param {string} options.pathWithoutQueryParams
 * @param {import("./MenuItem").Item[]} options.menuItems
 * @returns {string[]}
 */
function getInitialOpenedMenuItemsIds({
  router,
  pathWithoutQueryParams,
  menuItems = [],
}) {
  /** @type {string[]} */
  const openedMenuItemsIds = [];
  menuItems?.forEach((item) => {
    const isActive = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
    });
    const isActiveDeep = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
      children: item.children,
    });
    if ((item.children ?? []).length > 0 && (isActive || isActiveDeep)) {
      openedMenuItemsIds.push(item.id);
    }
  });
  return openedMenuItemsIds;
}

/**
 * @typedef {{
 * menuItems: import("./MenuItem").Item[],
 * isOpened: boolean,
 * onClose: () => void,
 * isMobileLayout: boolean,
 * }} Props
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
function Menu({ menuItems: _menuItems, isOpened, onClose, isMobileLayout }) {
  const router = useRouter();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const canPerform = useCanPerform();

  const { block, unblock } = useScrollBlocker();

  const { userAdmin, isRoot, loading } = useAuth();

  const { colorMode, setColorMode } = useColorMode();
  const { setPreviousColorMode } = usePreviousColorMode();

  /**
   * Gère le changement de thème.
   * @param {import("@chakra-ui/system").ColorMode} colorMode
   */
  function handleChangeTheme(colorMode) {
    setColorMode(colorMode);
    setPreviousColorMode(colorMode);
  }

  const menuItems = useMemo(() => {
    return prepare(_menuItems, { canPerform, isRoot });
  }, [_menuItems, canPerform, isRoot]);

  const [openedMenuItemsIds, setOpenedMenuItemsIds] = useState(() => {
    const pathWithoutQueryParams = router.asPath?.split?.(/[?#]/)[0];
    return getInitialOpenedMenuItemsIds({
      menuItems,
      router,
      pathWithoutQueryParams,
    });
  });

  const openMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      if (item.depth === 0) {
        setOpenedMenuItemsIds([item.id]);
      } else {
        setOpenedMenuItemsIds((openedMenuItems) => [
          ...openedMenuItems,
          item.id,
        ]);
      }
    },
    [],
  );

  const closeMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      setOpenedMenuItemsIds((openedMenuItems) => {
        const newOpenedMenuItems = [...openedMenuItems];
        const index = newOpenedMenuItems.indexOf(item.id);
        if (index > -1) {
          newOpenedMenuItems.splice(index, 1);
        }
        return newOpenedMenuItems;
      });
    },
    [],
  );

  useEffect(() => {
    if (isOpened && isMobileLayout) {
      block();
    } else {
      unblock();
    }
  }, [block, isMobileLayout, isOpened, unblock]);

  /**
   * Gère le changement d’environnement par défaut.
   */
  function handleChangeDefaultEnvironment(event) {
    setDefaultEnvironments(event.target.value);
  }

  return (
    <Flex
      sx={{
        "@media print": {
          display: "none",
        },
      }}
      position="fixed"
      top="4rem"
      left="0"
      bottom="0"
      right="0"
      pointerEvents="none">
      {/* backdrop */}
      <Box
        pointerEvents={isOpened && isMobileLayout ? "all" : "none"}
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        backgroundColor="rgba(0, 0, 0, 0.25)"
        opacity={isOpened && isMobileLayout ? 1 : 0}
        transition="opacity 0.2s"
        onClick={onClose}
      />
      <Box
        pointerEvents="all"
        w={WIDTH_MENU}
        overflowY="auto"
        backgroundColor="gray.700"
        transform={{
          base: isOpened ? "translateX(0)" : "translateX(-100%)",
          lg: "translateX(0)",
        }}
        transition="all 0.25s ease-in-out"
        boxShadow={{
          base: isOpened
            ? "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
            : "none",
          lg: "none",
        }}
        role="menubar">
        {isMobileLayout && (
          <>
            <Box pl="4px">
              <DarkMode>
                <Box px="16px" py="8px">
                  <ThemeSwitch
                    colorMode={colorMode}
                    setColorMode={handleChangeTheme}
                  />
                </Box>

                <ChakraMenu placement={"bottom"} isLazy={true}>
                  <ChakraMenuButton>
                    <Box px="16px" py="8px">
                      {loading ? (
                        <Spinner />
                      ) : (
                        <UsersAvatar size="xs" user={userAdmin} />
                      )}
                    </Box>
                  </ChakraMenuButton>
                  <Portal>
                    <UserMenu />
                  </Portal>
                </ChakraMenu>
                <HStack pr="0.25rem">
                  <EnvironmentSelect
                    width="100%"
                    isMultiple={true}
                    value={defaultEnvironments}
                    onChange={handleChangeDefaultEnvironment}
                  />
                </HStack>
              </DarkMode>
            </Box>
            <Box mt=".5rem">
              <Divider />
            </Box>
          </>
        )}

        {menuItems?.map?.((item) => (
          <MenuItem
            item={item}
            openedMenuItemsIds={openedMenuItemsIds}
            openMenuItem={openMenuItem}
            closeMenuItem={closeMenuItem}
            key={item.id}
          />
        ))}
      </Box>
    </Flex>
  );
}

export default Menu;
