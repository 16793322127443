// deps
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";
import { useDisclosure } from "@chakra-ui/react";

// containers
import Modal from "../containers/TwoFactorAuthentication/Modal";

/**
 * @typedef {object} TwoFaContextValue
 * @property {(data) => Promise<any>} handleOtpChallenge
 */

/** @type {TwoFaContextValue} */
const DefaultValue = {
  handleOtpChallenge: (data) => new Promise((resolve, reject) => {}),
};

/**
 * @typedef {object} TwoFaStateValue
 * @property {EventTarget} dispatcher
 * @property {import("../types/TwoFaChallenge").TwoFaChallenge} data
 */

/** @type {TwoFaStateValue | null} */
const DEFAULT_VALUE = null;

const TwoFaContext = createContext(DefaultValue);

export function TwoFaProvider(props) {
  const { children } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [otp, setOtp] = useState(DEFAULT_VALUE);

  const handleOtpChallenge = useCallback(
    (data) => {
      return new Promise((resolve, reject) => {
        onOpen();
        const dispatcher = new EventTarget();
        dispatcher.addEventListener(
          "otpSuccess",
          /**
           * @param {CustomEvent} event
           * @returns {void}
           */
          function (event) {
            resolve(event.detail);
          },
        );
        dispatcher.addEventListener(
          "otpError",
          /**
           * @param {CustomEvent} event
           * @returns {void}
           */
          function (event) {
            reject({
              body: {
                errors: [
                  {
                    title: "ValidationException",
                    detail: event.detail,
                    status: 422,
                    meta: {},
                  },
                ],
              },
            });
          },
        );
        setOtp({
          data: data.data,
          dispatcher,
        });
      });
    },
    [onOpen],
  );

  /**
   * @param {object} fields
   * @returns {void}
   */
  function handleSubmit(fields) {
    otp?.dispatcher?.dispatchEvent(
      new CustomEvent("otpSuccess", {
        detail: {
          otp_code: fields.data.otp_code,
        },
      }),
    );
    onClose();
  }

  function handleClose() {
    onClose();
    otp?.dispatcher?.dispatchEvent(
      new CustomEvent("otpError", {
        detail:
          "La modification de ce formulaire n'est pas autorisée, tant que l'authentification à deux facteurs n'est pas validée.",
      }),
    );
  }

  const value = useMemo(
    function () {
      return { handleOtpChallenge };
    },
    [handleOtpChallenge],
  );

  return (
    <>
      {otp?.data && (
        <Modal
          onClose={handleClose}
          isOpen={isOpen}
          onSubmit={handleSubmit}
          resource={otp.data}
        />
      )}

      <TwoFaContext.Provider value={value}>{children}</TwoFaContext.Provider>
    </>
  );
}

export function useTwoFa() {
  return useContext(TwoFaContext);
}
