import { createContext } from "react";

/**
 * @typedef {object} SplashContextValue
 * @property {((image: string | undefined) =>  string) | null} setSrc
 */

/** @type {SplashContextValue} **/
const DefaultValue = {
  setSrc: null,
};

export const SplashContext = createContext(DefaultValue);
