import { Box, Link, VStack } from "@chakra-ui/react";
import { createContext, useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import Error from "../../components/Error";
import { DEFAULT_CONFIGURATION } from "../../constants/api";
import apiGetErrorStatus from "../../helpers/api/getErrorStatus";
import useApiFetcher from "../../hooks/useApiFetcher";
import generateApiUri from "../../libraries/utils/generateApiUrl";

/**
 * @param {object} params
 * @param {string[]} params.fields
 **/
export function getConfigurationUrl({ fields }) {
  return generateApiUri({
    id: "@configuration",
    query: {
      fields,
    },
  });
}

/**
 * @typedef {object} ConfigurationContextValue
 * @property {import("../../types/Configuration").Configuration} configuration
 * @property {() => void} mutate
 */

/** @type {ConfigurationContextValue} */
const DefaultValue = {
  configuration: DEFAULT_CONFIGURATION,
  mutate: () => {},
};

export const ConfigurationContext = createContext(DefaultValue);

/**
 * Détermine si on doit afficher children ou non
 * @param {any} error
 * @returns {boolean}
 */
function shouldRenderChildren(error) {
  const maintenanceRequest = 503 === apiGetErrorStatus({ error });

  if (error) {
    return maintenanceRequest;
  }

  return true;
}

/**
 * @typedef {object} Props
 * @property {string} [configurationUrl]
 * @property {import("../../types/Configuration").Configuration} [initialConfiguration]
 * @property {any} [initialConfigurationError]
 * @property {React.ReactNode} children
 */
/** @param {Props} props */
export const ConfigurationProvider = ({
  configurationUrl,
  initialConfiguration: _initialConfiguration,
  initialConfigurationError,
  children,
}) => {
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const apiFetcher = useApiFetcher();

  const initialConfiguration = useRef(_initialConfiguration);

  const { data, mutate, error } = useSWR(configurationUrl ?? null, apiFetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: true,
    focusThrottleInterval: 600000,
    refreshInterval: 0,
    dedupingInterval: 0,
    fallbackData: {
      data: initialConfiguration.current,
    },
  });

  /** @type {import("../../types/Configuration").Configuration} */
  const configuration = data?.data; // forcing type as the configuration cannot be undefined due to fallbackData
  const value = useMemo(() => {
    return {
      configuration,
      mutate,
    };
  }, [configuration, mutate]);

  const handleClickShowDetail = useCallback(() => {
    setIsDetailVisible((v) => !v);
  }, []);

  return (
    <ConfigurationContext.Provider value={value}>
      {shouldRenderChildren(error ?? initialConfigurationError) ? (
        children
      ) : (
        <Box p="1rem">
          <Error.Global
            type="viewAny"
            error={error ?? initialConfigurationError}
          />
          <VStack mt="1rem">
            <Link onClick={handleClickShowDetail} fontSize="0.75rem">
              <FormattedMessage defaultMessage="Afficher plus de détail" />
            </Link>
            {isDetailVisible && (
              <Box
                maxW="full"
                overflowX="auto"
                whiteSpace="pre"
                fontFamily="monospace"
                borderWidth="1px">
                {JSON.stringify(
                  error ??
                    initialConfigurationError ??
                    "Une erreur inconnue s'est produite",
                  null,
                  2,
                )}
              </Box>
            )}
          </VStack>
        </Box>
      )}
    </ConfigurationContext.Provider>
  );
};
