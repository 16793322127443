// deps
import { useContext } from "react";
import { useIntl } from "react-intl";

// components
import Image from "../../components/Image";

// utils
import { SplashContext } from "../../contexts/Splash";

const defaultDimensions = [640, 360];

/**
 * @typedef {object} Props
 * @property {import("../../types/common/Image").Image} image
 * @property {Array<number, number>} [dimensions]
 */

/**
 * @param {Props & import("../../components/Image").Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export default function SplashImage(props) {
  const { image, dimensions = defaultDimensions, ...otherProps } = props;

  const { setSrc } = useContext(SplashContext);

  const intl = useIntl();

  if (!setSrc) {
    throw new Error("SplashImage: SplashContext is not provided");
  }

  return (
    <Image
      {...otherProps}
      dimensions={dimensions}
      src={setSrc(image)}
      alt={intl.formatMessage(
        {
          id: "raiden.admin.components.Splash.Image.texts.alt",
          defaultMessage:
            "Illustration {image, select, delete {de suppression} hard_delete {de suppression définitive} enable {d'activation} disable {de désactivation} restore {de restauration} error {d’erreur} documents {de documents} home {d’accueil} done {de finition} confirm {de confirmation} choice {de choix} select {de sélection} empty {de vide} created {de création} publish {de publication} save {de sauvegarde} reject {de rejet} default {par défaut} add {d’ajout} other {{image}}}",
        },
        { image },
      )}
    />
  );
}
