/* istanbul ignore file */

// deps
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import cookie from "cookie";

// constants
import { COOKIES_NAME_VALUE_NEXT_LOCALE } from "../constants/cookies";
import {
  LOCALES_LANG_TRANSLATE_NAME_LIST,
  DEFAULT_LOCALE,
} from "../constants/locales";
import browser from "../constants/browser";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

const supportedLocales = (
  process.env.NEXT_PUBLIC_SUPPORTED_LOCALES_TRANSLATE ?? ""
).split(/, ?/);

/**
 * @callback SetLocale
 * @param {string} locale
 * @param {object} options
 * @param {boolean} [options.saveInCookie=false]
 */

/**
 * @typedef {object} LocaleTranslate
 * @property {string} [icon]
 * @property {string} id
 * @property {import("react-intl").MessageDescriptor} [name]
 * @property {string} native_name
 */

/**
 * @typedef {object} UseLocaleReturn
 * @property {SetLocale} setLocale
 * @property {string} locale
 * @property {string[]} locales
 * @property {LocaleTranslate[]} localesTranslate
 * @property {string} defaultLocale
 */

/**
 * Retourne différentes composants pour récupérer la liste des langues disponibles,
 *   la langue courante ainsi qu’une méthode pour modifier la langue courante.
 */
export default function useLocale() {
  const router = useRouter();

  /**
   * Change la langue utilisée.
   */
  const setLocale = useCallback(
    /** @type {SetLocale} */
    function (locale, options = {}) {
      const { saveInCookie = false } = options;

      if (browser && saveInCookie) {
        document.cookie = cookie.serialize(
          COOKIES_NAME_VALUE_NEXT_LOCALE,
          locale,
          {
            path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
            secure: true,
          },
        );
      }

      router.replace(
        { pathname: router.pathname, query: router.query },
        undefined,
        {
          locale,
          shallow: false,
        },
      );
    },
    [router],
  );

  // liste des langues disponibles
  /** @type {string[]} **/
  const patchedLocales = useMemo(() => {
    /** @type {string[]} */
    const patchedLocales =
      router.locales?.filter(
        (locale) => locale !== process.env.NEXT_PUBLIC_FAKE_LOCALE,
      ) ?? [];
    return patchedLocales;
  }, [router.locales]);

  /** @type {LocaleTranslate[]} **/
  const patchedLocalesTranslate = useMemo(
    () =>
      LOCALES_LANG_TRANSLATE_NAME_LIST?.filter((locale) =>
        supportedLocales.includes(locale.id),
      ) ?? [],
    [],
  );

  const values = useMemo(() => {
    /** @type {UseLocaleReturn} */
    const values = {
      setLocale,
      locale: router.locale ?? DEFAULT_LOCALE, // langue courante
      locales: patchedLocales, // liste des langues disponibles
      localesTranslate: patchedLocalesTranslate, // liste des langues disponibles pour traduction des modules
      defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? DEFAULT_LOCALE, // langue par défaut
    };

    return values;
  }, [patchedLocales, patchedLocalesTranslate, router.locale, setLocale]);

  return values;
}
