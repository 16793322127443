import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { MdErrorOutline } from "react-icons/md";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {string} advisedText
 */

export default function AdvisedFormLabel({ label, advisedText }) {
  return (
    <Flex>
      {label}
      <Tooltip placement="top" label={advisedText} fontSize="md">
        <Flex alignItems="center">
          <Icon as={MdErrorOutline} color="orange.400" ml=".25rem" />
        </Flex>
      </Tooltip>
    </Flex>
  );
}
