/***
 * Recherche l'environement passé en paramètre
 * @param {object} param0
 * @param {number} param0.id
 * @param {import("../../../types/Configuration").ConfigurationEnvironment[] | undefined } param0.environmentList
 * @returns {import("../../../types/Configuration").ConfigurationEnvironment[] | object}
 */
export default function environmentsGetEnvironmentById({
  id,
  environmentList,
}) {
  if (environmentList) {
    return environmentList.find((environment) => environment.id === id);
  }

  return {};
}
