import { extendTheme } from "@chakra-ui/react";
import breakpoints from "./breakpoints";
import Checkbox from "./components/checkbox";
import { FORM_THEME } from "./components/form";
import Input from "./components/input";
import Link from "./components/link";
import Radio from "./components/radio";
import Select from "./components/select";
import Switch from "./components/switch";
import Table from "./components/table";
import Text from "./components/text";
import Textarea from "./components/textarea";
import styles from "./styles";

const theme = extendTheme({
  breakpoints,
  colors: {
    brandPrimary: {
      50: "#E5F6FF",
      100: "#B8E7FF",
      200: "#8AD7FF",
      300: "#5CC7FF",
      400: "#2EB7FF",
      500: "#00A8FF",
      600: "#0086CC",
      700: "#006599",
      800: "#004366",
      900: "#002233",
    },
    brandSecondary: {
      50: "#FDEDE8",
      100: "#F9CCBE",
      200: "#F5AB94",
      300: "#F18A6A",
      400: "#ED6A40",
      500: "#E94916",
      600: "#BA3A12",
      700: "#8C2C0D",
      800: "#5D1D09",
      900: "#2F0F04",
    },
    darkGray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
    seasonBadge: {
      low: {
        200: "#90CDF4",
        300: "#B794F4",
        500: "#4299E1",
        600: "#805AD5",
        700: "#2B6CB0",
      },
      medium: {
        200: "#9DECF9",
        300: "#4FD1C5",
        500: "#48BB78",
        600: "#00B5D8",
        700: "#25855A",
      },
      high: {
        200: "#FAF089",
        300: "#F687B3",
        500: "#ED8936",
        600: "#D53F8C",
        700: "#B7791F",
      },
      event: {
        200: "#FBD38D",
        300: "#FC8181",
        500: "#F6AD55",
        600: "#E53E3E",
        700: "#C05621",
      },
      none: {
        200: "#CBD5E0",
        300: "#4299E1",
        500: "#718096",
        600: "#2C5282",
        700: "#1A202C",
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-brandPrimary-500)",
  },
  components: {
    Checkbox,
    Form: FORM_THEME,
    Input,
    Link,
    Radio,
    Select,
    Switch,
    Table,
    Text,
    Textarea,
  },
  styles,
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;

export const WIDTH_MENU = "13.2rem";
