import { createContext, useContext, useMemo } from "react";
import { useSearchInfiniteContext } from "../../contexts/SearchInfinite/useSearchInfiniteContext";

/**
 * @typedef {object} DataHandlerInfiniteContextValue
 * @property {import("react").FC} SplashNoResult
 * @property {import("react").FC<{ error: import("../../types/Api/ApiError").ApiError}>} SplashError
 * @property {import("react").ReactElement} loadingElement
 */

/**
 * @template [T=any]
 * @param {object} props
 * @param {import("swr/infinite").SWRInfiniteResponse<T>} props.swrInfiniteResponse the SWR response
 * @param {boolean} [props.shouldRenderWithEmptyData] if true, the children will be rendered even if the data is empty
 * @param {import("react").ReactElement} [props.noResultElement] the element to render if the data is empty
 * @param {boolean} [props.shouldRenderLoadingElement]
 * @param {boolean} [props.shouldRenderErrorElement]
 * @param {boolean} [props.keepPreviousData]
 * @param {(params: {children: import("react").ReactNode}) => import("react").ReactElement} [props.wrapper] the wrapper that will be used to render any interal elements
 * @param {(response: T[]) => import("react").ReactElement} [props.children]
 */
export function DataHandlerInfinite({
  swrInfiniteResponse,
  shouldRenderWithEmptyData = false,
  noResultElement,
  shouldRenderLoadingElement = true,
  shouldRenderErrorElement = true,
  keepPreviousData = false,
  wrapper = ({ children }) => <>{children}</>,
  children,
}) {
  const { loadingElement, SplashError, SplashNoResult } =
    useDataHandlerInfinite();

  const { form, autoSubmit } = useSearchInfiniteContext();

  const Loading = useMemo(() => {
    return loadingElement ?? wrapper(loadingElement);
  }, [loadingElement, wrapper]);

  if (
    (form.formState.isDirty &&
      autoSubmit === true &&
      keepPreviousData === false) ||
    (swrInfiniteResponse.isValidating && keepPreviousData === false)
  ) {
    return shouldRenderLoadingElement ? Loading : null;
  }

  if (swrInfiniteResponse.data !== undefined) {
    const apiResponses = swrInfiniteResponse.data;
    if (
      apiResponses.length === 1 &&
      // @ts-ignore
      (apiResponses[0]?.data?.length ?? 0) === 0 &&
      !shouldRenderWithEmptyData
    ) {
      return noResultElement ?? wrapper({ children: <SplashNoResult /> });
    }
    return children ? children(swrInfiniteResponse.data) : null;
  }

  if (!swrInfiniteResponse.isValidating && swrInfiniteResponse.error) {
    return shouldRenderErrorElement
      ? wrapper({
          children: <SplashError error={swrInfiniteResponse.error} />,
        })
      : null;
  }

  return null;
}

/** @type {DataHandlerInfiniteContextValue} */
const DEFAULT_VALUE = {
  loadingElement: <></>,
  SplashError: () => null,
  SplashNoResult: () => null,
};

const DataHandlerInfiniteContext = createContext(DEFAULT_VALUE);

/**
 * @returns {DataHandlerInfiniteContextValue}
 */
export function useDataHandlerInfinite() {
  const value = useContext(DataHandlerInfiniteContext);
  if (value === DEFAULT_VALUE) {
    throw new Error(
      "useDataHandlerInfinite must be used within a DataHandlerProvider",
    );
  }
  return value;
}

/**
 * @typedef {object} Props
 * @property {DataHandlerInfiniteContextValue} value
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function DataHandlerInfiniteProvider({ value, children }) {
  return (
    <DataHandlerInfiniteContext.Provider value={value} children={children} />
  );
}
