/* istanbul ignore file */

// deps
import { useCallback } from "react";
import cookie from "cookie";

// libraires
import generateApiUri from "../libraries/utils/generateApiUrl";
import getInlineCookies from "@splitfire-agency/raiden-library/dist/libraries/utils/getInlineCookies";

// helpers
import { apiGetErrorStatus } from "../helpers/api";
import nextGetForwardedHeaders from "../helpers/next/getForwadedHeaders";

// hooks
import useLocale from "./useLocale";
import useAuth from "./useAuth";

// contexts
import { useMaintenanceMode } from "../contexts/MaintenanceMode";
import { useTwoFa } from "../contexts/TwoFaContext";

// constants
import browser from "../constants/browser";

/**
 * @typedef {object} FetchOptions
 * @property {string} [inputMode="json"]
 * @property {string} [outputMode="json"] Format dans lequel les données reçues doivent être retournées.
 * @property {boolean} [resolveOk=true] Est-ce qu’il faut rejetter la promesse si le code d’erreur est différent de 2xx.
 * @property {string} [locale]
 * @property {boolean} [useOtpChallenge] Permet de définir si on doit gérer le challenge OTP
 * @property {() => void} [logout]
 * @property {(data) => Promise<any>} [handleOtpChallenge]
 */

async function fetchCsrfCookie() {
  return fetch(
    generateApiUri({
      id: "@sanctum.csrfCookie",
    }),
    {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    },
  ).then(async function (response) {
    if (!response.ok) {
      throw await response.json();
    }
  });
}

/**
 * @param {any} resource
 * @param {any} init
 * @param {FetchOptions} options
 * @param {object} xsrfOptions
 * @param {any} xsrfOptions.xsrfToken
 * @param {number} [xsrfOptions.retry]
 */
async function fetchResource(resource, init, options = {}, xsrfOptions) {
  const {
    inputMode = "json",
    outputMode = "json",
    resolveOk = true,
    logout,
    handleOtpChallenge,
    useOtpChallenge = true,
  } = options;

  const { xsrfToken, retry: xsrfRetry = 0 } = xsrfOptions;

  return fetch(resource, {
    ...init,
    headers: {
      ...init?.headers,
      ...("csv" === outputMode && {
        Accept: "application/json",
      }),
      ...("pdf" === outputMode && {
        Accept: "application/json",
      }),
      ...("json" === inputMode && { "Content-Type": "application/json" }),

      ...("json" === outputMode && { Accept: "application/json" }),
      "X-XSRF-TOKEN": xsrfToken,
    },
    body: "json" === inputMode ? JSON.stringify(init.body) : init.body,
    credentials: init?.credentials ?? "include",
  }).then(async function (response) {
    let tempResponse = response;
    switch (outputMode) {
      case "json": {
        if ("application/json" === response.headers.get("content-type")) {
          tempResponse = await response.json();
        }
        break;
      }
      case "csv": {
        if (
          "text/xml; charset=UTF-8" === response.headers.get("content-type") ||
          "text/csv; charset=UTF-8" === response.headers.get("content-type")
        ) {
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1]
            : "";

          tempResponse = {
            // @ts-ignore
            blob: await response.blob(),
            contentType: "blob",
            filename:
              filename.length > 0
                ? filename.replace(/"/g, "").trim()
                : undefined,
          };
        } else {
          if ("application/json" === response.headers.get("content-type")) {
            tempResponse = await response.json();
          }
        }
        break;
      }
      case "pdf": {
        if ("application/pdf" === response.headers.get("content-type")) {
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1]
            : "";

          tempResponse = {
            // @ts-ignore
            blob: await response.blob(),
            contentType: "blob",
            filename:
              filename.length > 0
                ? filename.replace(/"/g, "").trim()
                : undefined,
          };
        } else {
          if ("application/json" === response.headers.get("content-type")) {
            tempResponse = await response.json();
          }
        }
        break;
      }
    }

    if (response.ok) {
      switch (response.status) {
        case 419: {
          if (
            /**
             * Si on obtient une seconde fois de suite un token expiré, on s’arrête.
             * Ce cas ne devrait pas arriver.
             */
            xsrfRetry < 1
          ) {
            return fetchCsrfCookie().then(function () {
              const { "XSRF-TOKEN": xsrfToken } = cookie.parse(document.cookie);

              return fetchResource(resource, init, options, {
                xsrfToken,
                retry: xsrfRetry + 1,
              });
            });
          }

          break;
        }

        case 401: {
          logout?.();
          break;
        }
        case 202: {
          // gestion de requpete en 202 avec lancement d'un challenge otp
          if (
            useOtpChallenge &&
            handleOtpChallenge &&
            // @ts-ignore
            tempResponse?.data?.two_factor_authentication_challenge_required
          ) {
            return handleOtpChallenge?.(tempResponse)
              .then((detail) => {
                //code otp dans response à injecter dans init.body en fonction de l'input mode json ou formData
                if (inputMode === "json") {
                  init.body = {
                    ...init.body,
                    meta: {
                      ...init.body.meta,
                      otp_code: detail.otp_code,
                    },
                  };
                }
                if (inputMode === "formData") {
                  init.body.append("meta[otp_code]", detail.otp_code);
                }
                return fetchResource(resource, init, options, {
                  xsrfToken,
                });
              })
              .catch((error) => {
                return Promise.reject(error);
              });
          }
        }
      }
    }

    if (resolveOk) {
      if (response.ok) {
        return tempResponse;
      } else {
        return Promise.reject({ body: tempResponse, status: response.status });
      }
    } else {
      if (response.ok) {
        return tempResponse;
      } else {
        return { body: tempResponse, status: response.status };
      }
    }
  });
}

/**
 * Défini la procédure pour effectuer une requête à l’API côté client.
 * @param {RequestInfo} resource
 * @param {RequestInit} init
 * @param {object} options
 * @param {string} [options.inputMode="json"] Format dans lequel sont envoyées les données.
 * @param {string} [options.outputMode="json"] Format dans lequel les données reçues doivent être retournées.
 * @param {boolean} [options.resolveOk=true] Est-ce qu’il faut rejetter la promesse si le code d’erreur est différent de 2xx.
 * @param {string} [options.locale]
 * @returns {Promise<any>}
 */
export function browserApiFetcher(resource, init, options = {}) {
  if (!browser) {
    throw new SyntaxError("Cannot use browserApiFetcher server-side.");
  }

  const { locale } = options;

  const cookies = cookie.parse(document.cookie);

  let xsrfToken = cookies["XSRF-TOKEN"];

  return Promise.resolve()
    .then(async function () {
      if (!xsrfToken) {
        return fetchCsrfCookie().then(function () {
          xsrfToken = cookie.parse(document.cookie)["XSRF-TOKEN"];
        });
      }
    })
    .then(function () {
      return fetchResource(
        resource,
        {
          ...init,
          headers: {
            ...init?.headers,
            ...(locale && { "Accept-Language": locale }),
          },
        },
        options,
        { xsrfToken },
      );
    });
}

/**
 * Défini la procédure pour effectuer une requête à l’API côté serveur.
 * @param {RequestInfo} resource
 * @param {RequestInit} [init]
 * @param {object} [options]
 * @param {string} [options.locale] Langue à utiliser pour effectuer la requête.
 * @param {string} [options.origin] Origine à utiliser pour effectuer la requête.
 * @param {object} [options.cookies={}] Cookies du client récupérés par le serveur
 * @param {import("http").IncomingMessage} [options.req] Requête initiale de la page
 * @param {boolean} [options.resolveOk=true] Est-ce qu’il faut rejetter la promesse si le code d’erreur est différent de 2xx.
 */
export function serverApiFetcher(resource, init, options = {}) {
  const { cookies = {}, req, locale, origin } = options;

  const xsrfToken = cookies["XSRF-TOKEN"];

  return Promise.resolve().then(function () {
    return fetchResource(
      resource,
      {
        ...init,
        headers: {
          ...nextGetForwardedHeaders({ req }),
          ...init?.headers,
          ...{
            cookie: getInlineCookies(cookies),
          },
          ...(origin && {
            origin,
          }),
          ...(locale && { "Accept-Language": locale }),
        },
      },
      options,
      { xsrfToken },
    );
  });
}

export function useBrowserApiFetcher() {
  const { locale } = useLocale();

  return useCallback(
    function (resource, init, options = {}) {
      return browserApiFetcher(resource, init, { ...options, locale });
    },
    [locale],
  );
}

export default function useApiFetcher() {
  const { locale } = useLocale();
  const { mutate } = useAuth();

  const { onOpen: maintenanceModeOnOpen, onClose } = useMaintenanceMode();
  const { handleOtpChallenge } = useTwoFa();

  return useCallback(
    /** @returns {Promise<any>}  */
    function (resource, init, options = {}) {
      const promise = browserApiFetcher(resource, init, {
        ...options,
        locale,
        logout: mutate,
        handleOtpChallenge: handleOtpChallenge,
      })
        .then((param) => {
          onClose();
          return param;
        })
        .catch((error) => {
          if (503 === apiGetErrorStatus({ error })) {
            maintenanceModeOnOpen();
          }
          return Promise.reject(error);
        });

      return promise;
    },
    [locale, maintenanceModeOnOpen, mutate, onClose, handleOtpChallenge],
  );
}
