// deps
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
  DarkMode,
  Spinner,
} from "@chakra-ui/react";
import Router from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import Head from "next/head";
import useSWR from "swr";

// components
import MaintenanceSplash from "../../components/MaintenanceSplash";
import Error from "@raiden/library-ui/components/Error";
import Logo from "../../components/Logo";

// hooks
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useApiFetcher from "@raiden/library-ui/hooks/useApiFetcher";
import { getString } from "@raiden/library-ui/hooks/router/useRouterString";

// libraries
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";
import getTitle from "../../libraries/utils/getTitle";
import generateApiUri from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useRouter } from "next/router";
import formRedirect from "@raiden/library-ui/libraries/utils/formRedirect";

// contexts
import { useMaintenanceMode } from "@raiden/library-ui/contexts/MaintenanceMode";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library-ui/constants/users";

export default function SignedSaml() {
  const intl = useIntl();

  const { logged, loading, userAdmin } = useAuth();

  const apiFetcher = useApiFetcher();
  const router = useRouter();

  const { data, error } = useSWR(
    generateApiUri({
      id: "@samlSso.view",
      query: {
        SAMLRequest: router?.query?.SAMLRequest
          ? encodeURIComponent(getString(router?.query?.SAMLRequest) ?? "")
          : undefined,
        RelayState: router?.query?.RelayState
          ? encodeURIComponent(getString(router?.query?.RelayState) ?? "")
          : undefined,
      },
    }),
    apiFetcher,
  );

  const saml = data;

  // on redirige l'utilisateur vers son application
  if (saml?.saml_authentication_required) {
    formRedirect({ url: saml.next, method: "post", data: saml.data });
  }

  useEffect(
    function () {
      if (
        !loading &&
        (!logged || USERS_USER_TYPE_VALUE_ADMIN !== userAdmin?.user_type)
      ) {
        Router.push({
          pathname: generateAdminPath({ id: "login" }),
          query: {
            next: Router.asPath,
          },
        });
      }
    },
    [logged, loading, userAdmin?.user_type],
  );

  const { isOpen: maintenanceModeIsOpen, onClose: maintenanceModeOnClose } =
    useMaintenanceMode();

  return (
    <>
      <Head>
        <title key="title">
          {getTitle(
            intl.formatMessage({
              defaultMessage: "Connexion service externe en cours",
            }),
          )}
        </title>
      </Head>
      <Modal
        isOpen={maintenanceModeIsOpen}
        size="xl"
        onClose={maintenanceModeOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            {intl.formatMessage({
              defaultMessage: "Maintenance en cours",
            })}
          </ModalHeader>

          <ModalBody>
            <MaintenanceSplash
              addon={
                <Button onClick={maintenanceModeOnClose}>
                  {intl.formatMessage({ defaultMessage: "Fermer" })}
                </Button>
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DarkMode>
        <Flex h="full" flexDir="column">
          <Flex
            flexGrow={1}
            width="full"
            alignItems={"center"}
            justifyContent={"center"}>
            <Box>
              <Box textAlign={"center"}>
                <Logo dimensions={[270, 40]} />
                {!error && (
                  <>
                    <Box mt="1rem">
                      <Spinner size="lg" color={"orange.500"} />
                    </Box>
                    <Box mt="1rem">
                      <Text>
                        {intl.formatMessage({
                          defaultMessage:
                            "Authentification en cours, veuillez patienter.",
                        })}
                      </Text>
                    </Box>
                    <Box mt="1rem">
                      <Text>
                        {intl.formatMessage({
                          defaultMessage:
                            "Une fois l'authentification terminée, vous serez automatiquement redirigé vers le site ...",
                        })}
                      </Text>
                    </Box>
                  </>
                )}

                {error && <Error.Global type="view" error={error} />}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </DarkMode>
    </>
  );
}

SignedSaml.propTypes = {
  children: PropTypes.node,
};
