// deps
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Text,
} from "@chakra-ui/react";

// components
import SplashImage from "./Image";

/**
 * @typedef {object} Props
 * @property {any} [title]
 * @property {any} [description]
 * @property {Array<number, number>} [dimensions]
 * @property {import("../../types/common/Image").Image | import("react").ReactNode} [image]
 * @property {any} [children]
 * @property {string} [maxW]
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export default function Splash(props) {
  const {
    title,
    description,
    image,
    children,
    maxW = "21.875rem",
    dimensions,
  } = props;

  return (
    <Box py="3rem">
      {image && (
        <Box maxW={maxW} mx="auto">
          {"string" === typeof image ? (
            // @ts-ignore
            <SplashImage dimensions={dimensions} image={image} />
          ) : (
            image
          )}
        </Box>
      )}

      <Alert
        bg="transparent"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center">
        {title && (
          <AlertTitle mt="1rem" mb="0.5rem" fontSize="lg">
            <Text>{title}</Text>
          </AlertTitle>
        )}
        {description && (
          <AlertDescription maxWidth="sm">
            <Text>{description}</Text>
          </AlertDescription>
        )}
      </Alert>
      {children}
    </Box>
  );
}
