// deps
// eslint-disable-next-line import/no-unresolved
import generateUri from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import { adminRouterWithBasePath } from "../../../constants/routers/admin";

// libraries
import encodeBase64 from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeBase64";

/**
 * Retourne le paramètre `t` utile à pampa.
 * Soit `format` soit `transformation` doit être présent afin que cette méthode fonctionne.
 * @param {object} param0
 * @param {object} param0.image
 * @param {string} [param0.format]
 * @param {object} [param0.transformation]
 * @param {number} [param0.width]
 * @param {number} [param0.height]
 * @param {"resize" | "crop"} [param0.mode]
 * @param {"cover" | "contain"} [param0.fit]
 * @param {boolean} [param0.trim]
 * @param {number} [param0.quality]
 * @param {string} [param0.outputFormat]
 */
function getTransformation({
  image = {},
  format,
  transformation,
  width,
  height,
  mode,
  fit,
  trim,
  quality,
  outputFormat,
}) {
  let t = {};

  let tempTransformation;

  if (transformation) {
    tempTransformation = transformation;
  } else if (format) {
    const { transformations } = image;

    const transformation = transformations?.[format];

    if (transformation) {
      tempTransformation = transformation;
    }
  }

  t = {
    width,
    height,
    mode,
    fit,
    trim,
    quality,
    outputFormat,
    ...(tempTransformation && {
      cropWidth: tempTransformation.width,
      cropHeight: tempTransformation.height,
      x: tempTransformation.x,
      y: tempTransformation.y,
    }),
  };

  const jsonT = JSON.stringify(t);

  if (jsonT.length > 2) {
    return { t: encodeBase64(jsonT) };
  }

  return undefined;
}

/**
 * Retourne l'URI de la route API demandée.
 * @param {object} params
 * @param {import("../../../types/Cover").Coverizable} params.image
 * @param {string} [params.format]
 * @param {object} [params.transformation]
 * @param {number} [params.width]
 * @param {number} [params.height]
 * @param {"resize" | "crop"} [params.mode]
 * @param {"cover" | "contain"} [params.fit]
 * @param {boolean} [params.trim]
 * @param {object} [params.query]
 * @param {number} [params.quality]
 * @param {string} [params.outputFormat]
 * @returns {string}
 */
export default function generatePampaUri({
  image,
  format,
  transformation,
  width,
  height,
  mode,
  fit,
  trim,
  query,
  quality,
  outputFormat,
}) {
  const transformationQuery = getTransformation({
    transformation,
    image,
    format,
    width,
    height,
    mode,
    fit,
    trim,
    quality,
    outputFormat,
  });

  const slug = image?.pampa_uri;

  // @ts-ignore
  return generateUri({
    router: adminRouterWithBasePath,
    id: "pampa",
    parameters: {
      slug,
    },
    query: {
      ...query,
      ...transformationQuery,
    },
  });
}
