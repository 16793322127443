/**
 * Calcul la taille pour pampa.
 * @param {object} param0
 * @param {import("../../../types/common/Avatar").AvatarSize} [param0.size]
 */
export default function getSizes({ size }) {
  switch (size) {
    case "xs":
      return { width: 24, height: 24 };

    case "sm":
      return { width: 32, height: 32 };

    case "md":
      return { width: 48, height: 48 };

    case "lg":
      return { width: 64, height: 64 };

    default: {
      return { width: undefined, height: undefined };
    }
  }
}
