import { mode } from "@chakra-ui/theme-tools";

/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const FORM_THEME = {
  parts: ["container", "requiredIndicator", "helperText"],
  baseStyle(props) {
    return {
      container: {
        label: {
          color: mode("gray.800", "whiteAlpha.900")(props),
        },
      },
    };
  },
};
