// deps
import { Box, Button } from "@chakra-ui/react";
import { isSameMonth } from "@internationalized/date";
import { useCalendarCell } from "@react-aria/calendar";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";

/**
 * @typedef Props
 * @property {import("@react-stately/calendar").CalendarState} state
 * @property {object} date
 * @property {object} currentMonth
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} setOpen
 * @property {boolean} shouldCloseOnDaySelect
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function CalendarCell({
  state,
  date,
  currentMonth,
  setOpen,
  shouldCloseOnDaySelect,
}) {
  let ref = useRef(null);
  let { cellProps, buttonProps, isSelected, isInvalid, formattedDate } =
    useCalendarCell({ date }, state, ref);

  let isOutsideMonth = !isSameMonth(currentMonth, date);

  const onCellClick = useCallback(() => {
    if (shouldCloseOnDaySelect) {
      setOpen(false);
    }
  }, [setOpen, shouldCloseOnDaySelect]);

  return (
    <Box as="td" {...cellProps} textAlign="center">
      <Button
        {...buttonProps}
        ref={ref}
        hidden={isOutsideMonth}
        size="sm"
        colorScheme={isInvalid ? "red" : "blue"}
        variant={isSelected ? "solid" : "ghost"}
        onClick={onCellClick}
        transition="none"
        minW="42px">
        {formattedDate}
      </Button>
    </Box>
  );
}

export default CalendarCell;

CalendarCell.propTypes = {
  state: PropTypes.object,
  date: PropTypes.object,
  currentMonth: PropTypes.object,
  setOpen: PropTypes.func,
  shouldCloseOnDaySelect: PropTypes.bool,
};
