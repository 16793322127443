// deps
import {
  IoEllipsisHorizontalCircleOutline,
  IoPauseOutline,
  IoPlayOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";

/* Restriction de la ressource */

export const RESOURCES_IS_RESTRICTED_MODE_VALUE_NO_RESTRICTION = "0";
export const RESOURCES_IS_RESTRICTED_MODE_VALUE_READ_ONLY = "1";
export const RESOURCES_IS_RESTRICTED_MODE_VALUE_TOTAL = "2";

export const RESOURCES_IS_RESTRICTED_MODES = {
  [RESOURCES_IS_RESTRICTED_MODE_VALUE_NO_RESTRICTION]: {
    id: RESOURCES_IS_RESTRICTED_MODE_VALUE_NO_RESTRICTION,
  },
  [RESOURCES_IS_RESTRICTED_MODE_VALUE_READ_ONLY]: {
    id: RESOURCES_IS_RESTRICTED_MODE_VALUE_READ_ONLY,
  },
  [RESOURCES_IS_RESTRICTED_MODE_VALUE_TOTAL]: {
    id: RESOURCES_IS_RESTRICTED_MODE_VALUE_TOTAL,
  },
};

export const RESOURCES_IS_RESTRICTED_MODE_LIST = Object.values(
  RESOURCES_IS_RESTRICTED_MODES,
);

export const resourcesIsRestrictedModeMessage = defineMessage({
  id: "raiden.library.constants.resources.isRestrictedMode",
  defaultMessage:
    "{isRestrictedMode, select, 0 {Aucune restriction} 1 {Lecture seule} 2 {Restriction totale} other {{isRestrictedMode}}}",
});

export const resourcesIsRestrictedModeDescriptionMessage = defineMessage({
  id: "raiden.library.constants.resources.isRestrictedMode.description",
  defaultMessage: `{isRestrictedMode, select,
      0 {N’appliquer aucune restriction}
      1 {Seul l’administrateur racine pourra modifier {subject}}
      2 {Seul l’administrateur racine pourra voir et modifier {subject}}
      other {{isRestrictedMode}}
    }`,
});

// Visibility

export const RESOURCES_VISIBILITY_VALUE_PUBLIC = "public";
export const RESOURCES_VISIBILITY_VALUE_PRIVATE = "private";

export const RESOURCES_VISIBILITIES = {
  [RESOURCES_VISIBILITY_VALUE_PUBLIC]: {
    id: RESOURCES_VISIBILITY_VALUE_PUBLIC,
  },
  [RESOURCES_VISIBILITY_VALUE_PRIVATE]: {
    id: RESOURCES_VISIBILITY_VALUE_PRIVATE,
  },
};

export const RESOURCES_VISIBILITY_LIST = Object.values(RESOURCES_VISIBILITIES);

export const resourcesVisibilityMessage = defineMessage({
  id: "raiden.library.constants.resources.visiblity",
  defaultMessage:
    "{visibility, select, public {Publique} private {Privée} other {{visibility}}}",
});

export const resourcesVisibilityDescriptionMessage = defineMessage({
  id: "raiden.library.constants.resources.visiblity.description",
  defaultMessage:
    "{visibility, select, public {Sera visible sur le site pour les utilisateurs connectés} private {Sera visible uniquement par les administrateurs} other {{visibility}}}",
});

export const RESOURCES_IS_EDITABLE_VALUE_TRUE = "1";
export const RESOURCES_IS_EDITABLE_VALUE_FALSE = "0";

export const RESOURCES_IS_EDITABLE_VALUES = {
  [RESOURCES_IS_EDITABLE_VALUE_TRUE]: {
    id: RESOURCES_IS_EDITABLE_VALUE_TRUE,
    position: 1,
  },
  [RESOURCES_IS_EDITABLE_VALUE_FALSE]: {
    id: RESOURCES_IS_EDITABLE_VALUE_FALSE,
    position: 2,
  },
};

export const RESOURCES_IS_EDITABLE_LIST = Object.values(
  RESOURCES_IS_EDITABLE_VALUES,
);

export const resourcesIsEditableMessage = defineMessage({
  id: "raiden.library.constants.resources.isEditable",
  defaultMessage:
    "{isEditable, select, 0 {Interdire l’édition} 1 {Autoriser l’édition} other {{isEditable}}}",
});

// Types d’objets supportant les couvertures

export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_CATEGORIES =
  "posts_categories";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_USERS = "users";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE =
  "posts_revisions_page";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE =
  "posts_revisions_article";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_FAQ =
  "posts_revisions_faq";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_SLIDESHOW_ITEM =
  "slideshow_item";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_CAMPAIGN = "campaign";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_DEPARTMENT = "department";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_REGION = "region";
export const RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_ROOM_PICTURE =
  "room_picture";

export const RESOURCES_COVERIZABLE_OBJECT_TYPES = {
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_CATEGORIES]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_CATEGORIES,
    model: "App\\Models\\Post\\Category",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE,
    model: "App\\Models\\Post\\Revision",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
    model: "App\\Models\\Post\\Revision",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_USERS]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_USERS,
    model: "App\\Models\\User",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_SLIDESHOW_ITEM]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_SLIDESHOW_ITEM,
    model: "App\\Models\\Slideshow\\Item",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_CAMPAIGN]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_CAMPAIGN,
    model: "App\\Models\\Campaign",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_DEPARTMENT]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_DEPARTMENT,
    model: "App\\Models\\Department",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_REGION]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_REGION,
    model: "App\\Models\\Region",
  },
  [RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_ROOM_PICTURE]: {
    id: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_ROOM_PICTURE,
    model: "App\\Models\\Room\\Picture",
  },
};

export const RESOURCES_COVERIZABLE_OBJECT_TYPE_LIST = Object.values(
  RESOURCES_COVERIZABLE_OBJECT_TYPES,
);

// Media-types (mime-types)

export const RESOURCES_MEDIA_TYPES = {
  "image/png": {
    friendlyName: "png",
  },
  "image/svg+xml": {
    friendlyName: "svg",
  },
  "image/vnd.wap.wbmp": {
    friendlyName: "wbmp",
  },
  "image/webp": {
    friendlyName: "webp",
  },
  "image/gif": {
    friendlyName: "gif",
  },
  "image/jpeg": {
    friendlyName: "jpeg",
  },
  "image/tiff": {
    friendlyName: "tiff",
  },
  "image/x-ms-bmp": {
    friendlyName: "bitmap",
  },
};

// States

const RESOURCES_STATES_VALUE_ENABLED = "enabled";
const RESOURCES_STATES_VALUE_DISABLED = "disabled";
const RESOURCES_STATES_VALUE_PENDING = "pending";

export const RESOURCES_STATES = {
  [RESOURCES_STATES_VALUE_ENABLED]: {
    id: RESOURCES_STATES_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoPlayOutline,
  },
  [RESOURCES_STATES_VALUE_DISABLED]: {
    id: RESOURCES_STATES_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    icon: IoPauseOutline,
  },
  [RESOURCES_STATES_VALUE_PENDING]: {
    id: RESOURCES_STATES_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    color: "orange.600",
    icon: IoEllipsisHorizontalCircleOutline,
  },
};

export const RESOURCES_STATES_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, enabled {activé} disabled {désactivé} pending {en attente} other {inconnu}}",
});
