import { useContext } from "react";
import { SearchInfiniteContext } from "./SearchInfiniteProvider";

/**
 * @template [TFields=any]
 * @template [TResponse=any]
 * @typedef {import("./SearchInfiniteProvider").SearchInfiniteContextValue<TFields, import("../../types/Api/ApiResponse").ApiResponse<TResponse>>} UseSearchInfiniteReturn
 */

export function useSearchInfiniteContext() {
  return useContext(SearchInfiniteContext);
}
