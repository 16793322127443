// deps
import { defineMessage } from "react-intl";

/* Redirections type */
export const REDIRECTIONS_URLS_TYPE_VALUE_REDIRECTION = "redirection";
export const REDIRECTIONS_URLS_TYPE_VALUE_SHORT_URL = "short_url";

export const REDIRECTIONS_URLS_TYPES = {
  [REDIRECTIONS_URLS_TYPE_VALUE_REDIRECTION]: {
    id: REDIRECTIONS_URLS_TYPE_VALUE_REDIRECTION,
    label: defineMessage({ defaultMessage: "redirection" }),
  },
  [REDIRECTIONS_URLS_TYPE_VALUE_SHORT_URL]: {
    id: REDIRECTIONS_URLS_TYPE_VALUE_SHORT_URL,
    label: defineMessage({ defaultMessage: "lien court" }),
  },
};

export const REDIRECTIONS_URLS_TYPE_LIST = Object.values(
  REDIRECTIONS_URLS_TYPES,
);

/* Redirections time */
export const REDIRECTIONS_URLS_TIME_VALUE_PERMANENT = "permanent";
export const REDIRECTIONS_URLS_TIME_VALUE_TEMPORARY = "temporary";

export const REDIRECTIONS_URLS_TIMES = {
  [REDIRECTIONS_URLS_TIME_VALUE_PERMANENT]: {
    id: REDIRECTIONS_URLS_TIME_VALUE_PERMANENT,
    label: defineMessage({ defaultMessage: "permanent" }),
  },
  [REDIRECTIONS_URLS_TIME_VALUE_TEMPORARY]: {
    id: REDIRECTIONS_URLS_TIME_VALUE_TEMPORARY,
    label: defineMessage({ defaultMessage: "temporaire" }),
  },
};

export const REDIRECTIONS_URLS_TIME_LIST = Object.values(
  REDIRECTIONS_URLS_TIMES,
);

/* Redirections sort */
export const REDIRECTIONS_URLS_SORT_VALUE_CREATED_ASC = "created_at_asc";
export const REDIRECTIONS_URLS_SORT_VALUE_CREATED_DESC = "created_at_desc";

export const REDIRECTIONS_URLS_SORTS = {
  [REDIRECTIONS_URLS_SORT_VALUE_CREATED_ASC]: {
    id: REDIRECTIONS_URLS_SORT_VALUE_CREATED_ASC,
    label: defineMessage({ defaultMessage: "Date de création croissante" }),
  },
  [REDIRECTIONS_URLS_SORT_VALUE_CREATED_DESC]: {
    id: REDIRECTIONS_URLS_SORT_VALUE_CREATED_DESC,
    label: defineMessage({ defaultMessage: "Date de création décroissante" }),
  },
};

export const REDIRECTIONS_URLS_SORT_LIST = Object.values(
  REDIRECTIONS_URLS_SORTS,
);
