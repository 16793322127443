// helpers
import usersIsRoot from "../isRoot";

/** @typedef {Array<string>} RequestedAuthorizations */

/**
 * Retourne vrai si l’authorisation comparée est présente dans la liste des authorisations.
 * @param {object} param0
 * @param {RequestedAuthorizations} param0.requestedAuthorizations
 * @param {Array<string>} param0.authorizations
 * @returns {boolean}
 */
function hasAuthorization({ requestedAuthorizations, authorizations }) {
  return requestedAuthorizations.every(function (requestedAuthorization) {
    return authorizations.includes(requestedAuthorization);
  });
}

/**
 * Retourne vrai si l’utilisateur a le droit d’effectuer cette action.
 * @param {object} param0
 * @param {import("../../../types/User").UserBase} [param0.user]
 * @param {Array | string} param0.authorizations
 * @param {string | number} [param0.environmentId]
 */
export default function usersCan({ user, authorizations, environmentId }) {
  /** @type {RequestedAuthorizations} */
  const AUTHORIZATIONS_DEAULT_VALUE = [];

  //  si le user est root on bypass les tests.
  if (usersIsRoot({ user })) {
    return true;
  }

  /** @type {RequestedAuthorizations} */
  const requestedAuthorizations = !Array.isArray(authorizations)
    ? [authorizations]
    : authorizations;

  /** on récupére les authorizations de l'utilisateur (user) courant. */
  const userAuthorizations = user?.meta?.authorizations ?? [];

  /* Si on ne filtre pas par environnement. */
  if (!environmentId) {
    return userAuthorizations.some(function (authorizationsByEnvironment) {
      return hasAuthorization({
        requestedAuthorizations,
        authorizations: authorizationsByEnvironment?.authorizations ?? [],
      });
    });
  } else {
    /** @type {number} */
    const _environmentId =
      "string" === typeof environmentId
        ? parseInt(environmentId)
        : environmentId;

    /** on parcourt toutes les Authorizations de l'utilisateur en recherchant l'objet associé à l'environnement passé en param */
    const userAuthorizationsAnyEnvironment = userAuthorizations.reduce(
      (userAuthorizationsAnyEnvironment, environment) => {
        if (
          environment.environment_id === _environmentId ||
          "*" === environment.environment_id
        ) {
          userAuthorizationsAnyEnvironment.push(
            ...(environment?.authorizations ?? []),
          );
        }

        return userAuthorizationsAnyEnvironment;
      },
      AUTHORIZATIONS_DEAULT_VALUE,
    );

    return hasAuthorization({
      requestedAuthorizations,
      authorizations: userAuthorizationsAnyEnvironment,
    });
  }
}
