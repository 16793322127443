import { useCallback, useState } from "react";

/**
 * @param {Function} func
 */
export function useDebounceCallback(func) {
  const [timeoutIdDebounce, settimeoutIdDebounce] = useState(
    /** @type {NodeJS.Timeout | undefined} */ (undefined),
  );
  const [timeoutIdSettled, setTimeoutIdSettled] = useState(
    /** @type {NodeJS.Timeout | undefined} */ (undefined),
  );
  const [isSettled, setIsSettled] = useState(true);
  const [args, setArgs] = useState(
    /** @type {any[] | undefined} */ (undefined),
  );

  const debouncedCallback = useCallback((...args) => {
    setArgs(args);
  }, []);

  if (args !== undefined) {
    clearTimeout(timeoutIdSettled);
    if (isSettled) {
      func(...args);
      setArgs(undefined);
      setIsSettled(false);
      setTimeoutIdSettled(
        setTimeout(() => {
          setIsSettled(true);
        }, 500),
      );
    } else {
      if (timeoutIdDebounce) {
        clearTimeout(timeoutIdDebounce);
      }
      settimeoutIdDebounce(
        setTimeout(() => {
          func(...args);
          setIsSettled(true);
        }, 500),
      );
      setArgs(undefined);
    }
  }

  return debouncedCallback;
}
