import usersCan from "../../users/can";

/**
 * Filtre les environnements en fonction d'une authorisation
 * @typedef {object} Props
 * @property {Array<string>} environments
 * @property {string} authorizations
 * @property {import("../../../types/User").UserBase} [user]
 */

/**
 * @param {Props} props
 * @returns {Array}
 */
export default function getEnvironmentListRelyOnAuthorizations({
  environments,
  authorizations,
  user,
}) {
  if (authorizations) {
    return environments.filter((id) =>
      usersCan({
        user,
        environmentId: id,
        authorizations,
      }),
    );
  }
  return environments;
}
