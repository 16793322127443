// deps
import { forwardRef } from "react";

// components
import EnvironmentSelect from "../EnvironmentSelect";

// context
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

const EnvironmentSelectWithoutAuthorizations = forwardRef(
  /**
   * @param {import("../EnvironmentSelect").Props} props
   */
  function EnvironmentSelectWithoutAuthorizations(props, ref) {
    const { ...otherProps } = props;

    const {
      configuration: { environments },
    } = useConfiguration();

    return (
      <EnvironmentSelect
        environments={environments}
        forwardedRef={ref}
        {...otherProps}
      />
    );
  },
);

export default EnvironmentSelectWithoutAuthorizations;
