// deps
import { useRef } from "react";
import { useDateSegment } from "@react-aria/datepicker";
import { Box, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
/**
 * @typedef {object} Props
 * @property {import("@react-stately/datepicker").DateSegment} segment
 * @property {import("@react-stately/datepicker").DateFieldState} state
 * @property {import("react").Ref<any>} ref
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function DateSegment({ segment, state }) {
  let ref = useRef(null);
  let { segmentProps } = useDateSegment(segment, state, ref);
  const textColor = useColorModeValue(
    segment.isPlaceholder
      ? "gray.500"
      : !segment.isEditable
      ? "gray.600"
      : "black",
    segment.isPlaceholder
      ? "white"
      : !segment.isEditable
      ? "gray.600"
      : "white",
  );
  return (
    <Box
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        fontVariantNumeric: "tabular-nums",
        boxSizing: "content-box",
      }}
      minWidth={
        segment.maxValue != null
          ? `${String(segment.maxValue).length}ch`
          : undefined
      }
      paddingX="0.5"
      textAlign="end"
      outline="none"
      rounded="md"
      color={textColor}
      _focus={{
        background: "blue.500",
        color: "white",
      }}>
      {segment.text ?? ""}
    </Box>
  );
}

export default DateSegment;

DateSegment.propTypes = {
  segment: PropTypes.shape({
    isPlaceholder: PropTypes.bool,
    isEditable: PropTypes.bool,
    text: PropTypes.string,
    maxValue: PropTypes.number,
  }),
  state: PropTypes.object,
  ref: PropTypes.object,
};
