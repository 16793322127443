import { AspectRatio, Box, Text } from "@chakra-ui/react";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { ICON_TYPES_PREFIX_MAP } from "../../constants/icons";

export const Icon = memo(
  /**
   * @typedef {object} Props
   * @property {string} icon
   * @property {string} [size]
   * @property {import("@chakra-ui/react").BoxProps["color"]} [color]
   */
  /**
   * @param {Props} props
   */
  function Icon({ icon: iconName, size: _size, color: _color }) {
    const prefix = `${iconName.split("_")[0]}_`;

    const iconType = useMemo(() => {
      return ICON_TYPES_PREFIX_MAP.get(prefix);
    }, [prefix]);

    const color = _color ?? "inherit";

    const [element, setElement] = useState(null);

    useEffect(() => {
      const loadIcon = async () => {
        try {
          const iconModule = await import(
            /* webpackMode: "lazy" */ `@raiden/library-ui/constants/icons/${iconName}`
          );
          setElement(() => iconModule.default);
        } catch (error) {
          setElement(null);
        }
      };

      loadIcon();
    }, [iconName]);

    const contextValue = useContext(IconContext);

    if (contextValue === DEFAULT_VALUES) {
      throw new Error("Icon must be used within an IconProvider");
    }

    const size = _size ?? contextValue.defaultSize;

    return (
      <AspectRatio ratio={1} w={size}>
        <>
          {(() => {
            switch (iconType?.type) {
              case "internal":
                return (
                  <Box display="block" color={color} w={size}>
                    {element ?? (
                      <Text as="span" fontSize="10px" opacity=".5">
                        <FormattedMessage defaultMessage="Icône introuvable" />
                      </Text>
                    )}
                  </Box>
                );
              case "materialSymbols":
                return (
                  <Box
                    as="span"
                    className="material-symbols-outlined"
                    display="block"
                    fontSize={size}
                    color={color}>
                    {iconName.replace(prefix, "")}
                  </Box>
                );
              default:
                return (
                  <Text as="span" fontSize="10px" opacity=".5">
                    <FormattedMessage defaultMessage="Icône introuvable" />
                  </Text>
                );
            }
          })()}
        </>
      </AspectRatio>
    );
  },
);

const DEFAULT_VALUES = {
  defaultSize: "1rem",
};

export const IconContext = createContext(DEFAULT_VALUES);

export function IconProvider({ defaultSize, children }) {
  const value = useMemo(() => {
    return { defaultSize };
  }, [defaultSize]);

  return <IconContext.Provider value={value} children={children} />;
}
