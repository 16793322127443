// deps
import { useRef } from "react";
import { useIntl } from "react-intl";
import Head from "next/head";
import NextLink from "next/link";
import PropTypes from "prop-types";
import { Box, Button, VStack, Center } from "@chakra-ui/react";
import { useRouter } from "next/router";

// components
import Splash from "@raiden/library-ui/components/Splash";
import PagePanel from "../../components/PagePanel";
import EnvironmentSelectWithAuthorizations from "../../components/EnvironmentSelectWithAuthorizations";

// libraries
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";

// contexts
import { useDefaultEnvironments } from "../../contexts/DefaultEnvironments";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

// helpers
import { environmentsGetEnvironmentById } from "@raiden/library-ui/helpers/environments";

/**
 * Permet de récupérer l'environnement par défaut
 * @param {Array} defaultEnvironments
 * @returns
 */
function getEnvironnementId(defaultEnvironments) {
  if (defaultEnvironments) {
    if (Array.isArray(defaultEnvironments)) {
      return defaultEnvironments[0] ?? "";
    } else {
      return defaultEnvironments;
    }
  }
  return "";
}

export default function Unauthorized(props) {
  const { authorized } = props;
  const intl = useIntl();

  const router = useRouter();

  const { defaultEnvironments, setDefaultEnvironments } =
    useDefaultEnvironments();

  const environmentRemanent = useRef(
    /** @type {import("@raiden/library-ui/types/Environment").Environment | null} */ (
      null
    ),
  );
  /**
   * Gère le changement d’environnement par défaut.
   */
  function handleChangeDefaultEnvironment(event) {
    setDefaultEnvironments([event.target.value]);
    router.reload();
  }

  const {
    configuration: { environments },
  } = useConfiguration();
  let environment;

  if (!authorized?.locally) {
    const environmentId = getEnvironnementId(defaultEnvironments);
    environment = environmentsGetEnvironmentById({
      id: parseInt(environmentId),
      environmentList: environments,
    });
    if (!environmentRemanent.current) {
      environmentRemanent.current = environment;
    }
  }

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            defaultMessage: "Page introuvable",
          })}
        </title>
      </Head>

      <PagePanel.Viewport>
        <VStack spacing="1rem">
          {!authorized?.globally && (
            <Splash
              title={intl.formatMessage({
                defaultMessage:
                  "Vous ne possédez par les autorisations nécessaires pour accéder à cette page.",
              })}
              description={intl.formatMessage({
                defaultMessage:
                  "L'accès à cette page nécessite des autorisations spécifiques. Merci de vous rapprocher de votre administrateur.",
              })}
              image="error"
            />
          )}

          {!authorized?.locally && (
            <>
              <Splash
                title={intl.formatMessage(
                  {
                    defaultMessage:
                      "Vous ne pouvez pas accéder à cette page sur l'environnement {name}",
                  },
                  {
                    name: environmentRemanent.current?.name,
                  },
                )}
                description={intl.formatMessage({
                  defaultMessage:
                    "L'accès à cette page nécessite des autorisations spécifiques, vous pouvez sélectionner un autre environnement dans cette liste :",
                })}
                image="error">
                <Center>
                  <Box width="15.625rem">
                    <EnvironmentSelectWithAuthorizations
                      name="environment"
                      isMultiple={false}
                      value={getEnvironnementId(defaultEnvironments)}
                      onChange={handleChangeDefaultEnvironment}
                    />
                  </Box>
                </Center>
              </Splash>
            </>
          )}

          <Box>
            <NextLink
              href={generateAdminPath({ id: "dashboard" })}
              passHref={true}>
              <Button variant="solid" as="a" colorScheme="brandSecondary">
                {intl.formatMessage({
                  defaultMessage: "Revenir à l’accueil",
                })}
              </Button>
            </NextLink>
          </Box>
        </VStack>
      </PagePanel.Viewport>
    </>
  );
}

Unauthorized.propTypes = {
  authorized: PropTypes.shape({
    globally: PropTypes.bool,
    locally: PropTypes.bool,
  }),
};
