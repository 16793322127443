import { useEffect, useState } from "react";
import { useFormState } from "react-hook-form";

function resolveApiError(errors, keys = []) {
  if (errors && Object.keys(errors).length === 0) {
    return;
  }
  if (keys.length === 0 && typeof errors === "object") {
    return errors;
  }
  if (typeof errors[keys[0]] === "object") {
    const newKeys = [...keys];
    newKeys.shift();
    return resolveApiError(errors[keys[0]], newKeys);
  }
  if (typeof errors[keys[0]] === "string") {
    return errors[keys[0]];
  }
  return null;
}

/**
 * @param {{
 * name: string,
 * render?: (params: {hasError: boolean, error: any}) => import("react").ReactNode,
 * }} props
 */
function FormErrorObserverRHF({ name, render }) {
  const { errors } = useFormState({ name });

  const [lastError, setLastError] = useState(null);

  const currentError = resolveApiError(errors, name.split("."));

  const hasError = Boolean(currentError);

  useEffect(() => {
    currentError && setLastError(currentError);
  }, [currentError]);

  return (
    <>{render?.({ hasError, error: currentError || lastError || null })}</>
  );
}

export default FormErrorObserverRHF;
