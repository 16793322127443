// Alias des methods.
export const TWO_FA_METHOD_VALUE_APP = "app";
export const TWO_FA_METHOD_VALUE_EMAIL = "email";
export const TWO_FA_METHOD_VALUE_SMS = "sms";

export const TWO_FA_METHODS = {
  [TWO_FA_METHOD_VALUE_APP]: {
    id: TWO_FA_METHOD_VALUE_APP,
  },
  [TWO_FA_METHOD_VALUE_EMAIL]: {
    id: TWO_FA_METHOD_VALUE_EMAIL,
  },
  [TWO_FA_METHOD_VALUE_SMS]: {
    id: TWO_FA_METHOD_VALUE_SMS,
  },
};

export const TWO_FA_METHOD_LIST = Object.values(TWO_FA_METHODS);
