/* Politiques */
export const POLICIES_POLICY_VALUE_VIEW = "view";
export const POLICIES_POLICY_VALUE_UPDATE = "update";
export const POLICIES_POLICY_VALUE_UPDATE_RECORDS_ACCOUNTS =
  "update_records_accounts";
export const POLICIES_POLICY_VALUE_UPDATE_SUBSCRIBER = "update_subscriber";
export const POLICIES_POLICY_VALUE_ATTACH_DEPARTMENT = "attach_department";
export const POLICIES_POLICY_VALUE_MERGE = "merge";
export const POLICIES_POLICY_VALUE_QUOTAS = "quotas";
export const POLICIES_POLICY_VALUE_PASSWORD_RESETS = "password_resets";
export const POLICIES_POLICY_VALUE_ACCESS_TOKENS = "access_tokens";
export const POLICIES_POLICY_VALUE_REVIEW = "review";
export const POLICIES_POLICY_VALUE_REQUEST_REVIEW = "request_review";
export const POLICIES_POLICY_VALUE_PUBLISH = "publish";
export const POLICIES_POLICY_VALUE_PENDING = "pending";
export const POLICIES_POLICY_VALUE_ENABLE = "enable";
export const POLICIES_POLICY_VALUE_DISABLE = "disable";
export const POLICIES_POLICY_VALUE_CREATE = "create";
export const POLICIES_POLICY_VALUE_UPDATE_ITEMS = "update_items";
export const POLICIES_POLICY_VALUE_SOFT_DELETE = "soft_delete";
export const POLICIES_POLICY_VALUE_DELETE = "delete";
export const POLICIES_POLICY_VALUE_RESTORE = "restore";
export const POLICIES_POLICY_VALUE_DOWNLOAD = "download";
export const POLICIES_POLICY_VALUE_DOWNLOAD_AUDIT_TRAIL =
  "download_audit_trail";
export const POLICIES_POLICY_VALUE_EXPORT = "export";
export const POLICIES_POLICY_VALUE_SOLD = "sold";
export const POLICIES_POLICY_VALUE_FINISH = "finish";
export const POLICIES_POLICY_VALUE_EXPORT_DOWNLOAD = "export-download";
export const POLICIES_POLICY_VALUE_BATCH_ROOMS = "batch_rooms";
export const POLICIES_POLICY_VALUE_CANCEL = "cancel";
export const POLICIES_POLICY_VALUE_RENTALS_WHITE_LABEL = "rentals_white_label";
export const POLICIES_POLICY_VALUE_ATTACH = "attach";
export const POLICIES_POLICY_VALUE_DETACH = "detach";
export const POLICIES_POLICY_VALUE_VIEW_MESSAGES = "view_messages";
export const POLICIES_POLICY_VALUE_VIEW_ISSUES = "view_issues";
export const POLICIES_POLICY_VALUE_VIEW_ANY_ITEMS = "view_any_items";
export const POLICIES_POLICY_VALUE_TWO_FACTOR_AUTHENTICATION =
  "two_factor_authentication";
export const POLICIES_POLICY_VALUE_CREATE_RANKINGS = "create_rankings";
export const POLICIES_POLICY_VALUE_UPDATE_PICTURES = "update_pictures";
export const POLICIES_POLICY_VALUE_MIGRATE_HOTEL = "migrate_hotel";
export const POLICIES_POLICY_VALUE_ACKNOWLEDGE = "acknowledge";
export const POLICIES_POLICY_VALUE_CREATE_ISSUE = "create_issue";
export const POLICIES_POLICY_VALUE_CREATE_MESSAGE = "create_message";
export const POLICIES_POLICY_VALUE_CREATE_HOTEL = "create_hotel";
export const POLICIES_POLICY_VALUE_OWNER_MODERATION = "owner_moderation";
export const POLICIES_POLICY_VALUE_ADMIN_MODERATION = "admin_moderation";
export const POLICIES_POLICY_VALUE_VIEW_AVAILABILITIES = "view_availabilities";
export const POLICIES_POLICY_VALUE_BATCH_AVAILABILITIES =
  "batch_availabilities";
