import { defineMessage } from "react-intl";

// const représentant les langues disponibles sur l'application
export const LOCALES = ["fr", "en"];

// default locale
export const DEFAULT_LOCALE = "fr";

export const LOCALES_LANG_NAMES = {
  fr: {
    id: "fr",
    name: "🇫🇷 Français",
  },
  en: {
    id: "en",
    name: "🇬🇧 English",
  },
};

export const LOCALES_LANG_NAME_LIST = Object.values(LOCALES_LANG_NAMES);

// translate locales
export const LOCALES_LANG_TRANSLATE_NAMES = {
  fr: {
    id: "fr",
    icon: "🇫🇷",
    native_name: "français",
    name: defineMessage({
      defaultMessage: "français",
    }),
  },
  en: {
    id: "en",
    icon: "🇬🇧",
    native_name: "English",
    name: defineMessage({
      defaultMessage: "anglais",
    }),
  },
};

export const LOCALES_LANG_TRANSLATE_NAME_LIST = Object.values(
  LOCALES_LANG_TRANSLATE_NAMES,
);

export const CUSTOMER_SPOKEN_LANGUAGES = [
  {
    code: "en",
    name: defineMessage({ defaultMessage: "🇬🇧 Anglais" }),
  },
  {
    code: "de",
    name: defineMessage({ defaultMessage: "🇩🇪 Allemand" }),
  },
  {
    code: "ru",
    name: defineMessage({ defaultMessage: "🇷🇺 Russe" }),
  },
  {
    code: "es",
    name: defineMessage({ defaultMessage: "🇪🇸 Espagnol" }),
  },
  {
    code: "it",
    name: defineMessage({ defaultMessage: "🇮🇹 Italien" }),
  },
  {
    code: "nl",
    name: defineMessage({ defaultMessage: "🇳🇱 Néerlandais" }),
  },
  {
    code: "pt",
    name: defineMessage({ defaultMessage: "🇵🇹 Portugais" }),
  },
  {
    code: "jp",
    name: defineMessage({ defaultMessage: "🇯🇵 Japonais" }),
  },
];
