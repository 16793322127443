// deps
import { Select } from "@chakra-ui/react";

// constants
import { LOCALES_LANG_NAMES } from "../../constants/locales";

// hooks
import useLocale from "../../hooks/useLocale";

export default function LocaleUiSelect(props) {
  const { ...otherProps } = props;

  const { locales } = useLocale();

  if (locales.length < 2) {
    return null;
  }

  return (
    <Select {...otherProps}>
      {locales.map(function (locale) {
        if (locale === "default") {
          return;
        }
        return (
          <option key={locale} value={locale}>
            {LOCALES_LANG_NAMES[locale].name}
          </option>
        );
      })}
    </Select>
  );
}
