// deps
// eslint-disable-next-line import/no-unresolved
import generateUri from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import extranet from "../../../constants/routers/extranet";

/**
 * Retourne l’URI de la route API demandée.
 * @returns {string}
 */
export default function generateExtranetUri(param0) {
  return generateUri({
    router: extranet,
    ...param0,
  });
}
