/**
 * Filtre les environnements à partir d'une liste d'autorisations
 * @param {object} params
 * @param {import("../../../types/Configuration").ConfigurationEnvironment[]} params.environments
 * @param {string | string[]} params.authorizations
 * @param {import("../../../hooks/useCanPerform").CanPerformCallback} params.canPerform
 * @returns {import("../../../types/Configuration").ConfigurationEnvironment[]}
 */
export function getAuthorizedEnvironments({
  environments,
  authorizations,
  canPerform,
}) {
  if (authorizations) {
    return (environments ?? []).filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations,
      }),
    );
  }
  return environments ?? [];
}
