import {
  CUSTOMERS_CONTACT_STATE_VALUE_EXPIRED,
  CUSTOMERS_CONTACT_TYPE_VALUE_PHONE,
  CUSTOMERS_CONTACT_USAGE_VALUE_DISPLAY,
} from "../../../../../constants/customers";
/**
 * @param {object} props
 * @param {Array<import("../../../../../types/Customer").CustomerContact>} [props.contacts]
 */
export default function getDisplayPhoneFromContacts({ contacts }) {
  if (!contacts || contacts.length === 0) {
    return undefined;
  }

  return contacts?.find(
    (contact) =>
      contact.type === CUSTOMERS_CONTACT_TYPE_VALUE_PHONE &&
      contact.usage === CUSTOMERS_CONTACT_USAGE_VALUE_DISPLAY &&
      contact.state !== CUSTOMERS_CONTACT_STATE_VALUE_EXPIRED,
  );
}
