import { useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useDebounceCallback } from "../../../hooks/useDebounceCallback";

/**
 * Un composant responsable de la soumission automatique d'un formulaire
 * @typedef {object} Props
 * @property {() => void} onSubmit
 * @property {boolean} [isDisabled]
 */
/**
 * @param {Props} props
 */
export function FormAutoSubmit({ onSubmit, isDisabled = false }) {
  const values = useWatch();

  const refLastValues = useRef(values);

  const isDisabledRef = useRef(isDisabled);

  isDisabledRef.current = isDisabled;

  const debouncedCallback = useDebounceCallback(() => {
    if (!isDisabledRef.current) {
      onSubmit();
    }
  });

  useEffect(() => {
    if (!isDisabled) {
      if (JSON.stringify(values) !== JSON.stringify(refLastValues.current)) {
        debouncedCallback();
      }
      refLastValues.current = values;
    }
  }, [isDisabled, debouncedCallback, values]);

  return null;
}
