import { defineMessage } from "react-intl";
import {
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
  RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE,
} from "../resources";
import {
  IoPauseOutline,
  IoPencilOutline,
  IoPlayOutline,
  IoTimeOutline,
} from "react-icons/io5";

export const POSTS_TYPE_VALUE_PAGE = "page";
export const POSTS_TYPE_VALUE_ARTICLE = "article";
export const POSTS_TYPE_VALUE_FAQ = "faq";

export const POSTS_TYPES = {
  [POSTS_TYPE_VALUE_PAGE]: {
    id: POSTS_TYPE_VALUE_PAGE,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_PAGE,
  },
  [POSTS_TYPE_VALUE_ARTICLE]: {
    id: POSTS_TYPE_VALUE_ARTICLE,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
  },
  [POSTS_TYPE_VALUE_FAQ]: {
    id: POSTS_TYPE_VALUE_FAQ,
    objectType: RESOURCES_COVERIZABLE_OBJECT_TYPE_VALUE_POSTS_REVISIONS_ARTICLE,
  },
};

export const POSTS_TYPE_LIST = Object.values(POSTS_TYPES);

export const postsTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, page {page} article {article} faq {Faq} other {{type}}}",
});

export const postsTypePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, page {pages} article {articles} faq {Faq} other {{type}}}",
});

export const postsTypePartitiveElisiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {d'une page} article {d'un article} faq {de la FAQ} other {d'un/d'une {type}}}",
});

export const postsTypePartitiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {de page} article {d’article} faq {d'article} other {d'un/d'une {type}}}",
});

export const postsTypePartitivePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, page {des pages} article {des articles} faq {de la FAQ} other {des {type}}}",
});

export const postsTypeIndefiniteSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {une page} article {un article} faq {un article} other {un/une {type}}}",
});

export const postsTypeDefiniteSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {la page} article {l’article} faq {l'article} other {le/la {type}}}",
});

export const postsTypeDefinitePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, page {les pages} article {les articles} faq {les articles} other {les {type}}}",
});

export const postsTypeDemonstrativeSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {cette page} article {cet article} faq {cet article} other {les {type}}}",
});

export const postsTypeDemonstrativePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, page {ces pages} article {ces articles} faq {ces articles} other {les {type}}}",
});

export const postsTypePossessiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, page {votre page} article {votre article} faq {votre article} other {votre {type}}}",
});

export const postsTypePossessivePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, page {vos pages} article {vos articles} faq {vos articles} other {vos {type}}}",
});

// États

export const POSTS_STATE_VALUE_DRAFT = "draft";
export const POSTS_STATE_VALUE_PUBLISHED = "published";
export const POSTS_STATE_VALUE_DISABLED = "disabled";
export const POSTS_STATE_VALUE_PENDING = "pending"; // status virtuel n'existe pas en base

export const POSTS_STATES = {
  [POSTS_STATE_VALUE_DRAFT]: {
    id: POSTS_STATE_VALUE_DRAFT,
    colors: ["gray.600", "gray.300"],
    color: "gray",
    icon: IoPencilOutline,
    enabledTab: true,
  },
  [POSTS_STATE_VALUE_PENDING]: {
    id: POSTS_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    color: "purple",
    icon: IoTimeOutline,
    enabledTab: false,
  },
  [POSTS_STATE_VALUE_PUBLISHED]: {
    id: POSTS_STATE_VALUE_PUBLISHED,
    colors: ["green.400", "green.400"],
    color: "green",
    icon: IoPlayOutline,
    enabledTab: true,
  },
  [POSTS_STATE_VALUE_DISABLED]: {
    id: POSTS_STATE_VALUE_DISABLED,
    colors: ["purple.600", "purple.600"],
    color: "purple",
    icon: IoPauseOutline,
    enabledTab: true,
  },
};

export const POSTS_STATE_LIST = Object.values(POSTS_STATES);

export const postsStateMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    published {publié}
    pending {en attente de publication}
    disabled {désactivé}
    other {{state}}
  }`,
});

export const postsStateVerbMessage = defineMessage({
  id: "raiden.library.constants.posts.state.verb",
  defaultMessage:
    "{state, select, draft {passer en brouillon} published {publier} disabled {désactiver} other {{type}}}",
});

export const postsStateParticipleMessage = defineMessage({
  id: "raiden.library.constants.posts.state.participle",
  defaultMessage:
    "{state, select, draft {passé(e) en brouillon} published {publié(e)} disabled {désactivé(e)} other {{type}}}",
});

// Tris

export const POSTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const POSTS_SORT_VALUE_CREATED_DESC = "created_desc";
export const POSTS_SORT_VALUE_UPDATED_ASC = "updated_asc";
export const POSTS_SORT_VALUE_UPDATED_DESC = "updated_desc";
export const POSTS_SORT_VALUE_POSITION_ASC = "position_asc";
export const POSTS_SORT_VALUE_POSITION_DESC = "position_desc";
export const POSTS_SORT_VALUE_PUBLICATION_DATE_ASC = "publication_date_asc";
export const POSTS_SORT_VALUE_PUBLICATION_DATE_DESC = "publication_date_desc";

/**
 * @typedef {object} PostSortValue
 * @property {import("../../types/Post").PostSort} id
 * @property {boolean} isPublic
 * @property {import("react-intl").MessageDescriptor} [label]
 */

/** @type {Record<import("../../types/Post").PostSort, PostSortValue>} */
export const POSTS_SORTS = {
  [POSTS_SORT_VALUE_CREATED_ASC]: {
    id: POSTS_SORT_VALUE_CREATED_ASC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_CREATED_DESC]: {
    id: POSTS_SORT_VALUE_CREATED_DESC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_UPDATED_ASC]: {
    id: POSTS_SORT_VALUE_UPDATED_ASC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_UPDATED_DESC]: {
    id: POSTS_SORT_VALUE_UPDATED_DESC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_POSITION_ASC]: {
    id: POSTS_SORT_VALUE_POSITION_ASC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_POSITION_DESC]: {
    id: POSTS_SORT_VALUE_POSITION_DESC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_PUBLICATION_DATE_ASC]: {
    id: POSTS_SORT_VALUE_PUBLICATION_DATE_ASC,
    isPublic: false,
  },
  [POSTS_SORT_VALUE_PUBLICATION_DATE_DESC]: {
    id: POSTS_SORT_VALUE_PUBLICATION_DATE_DESC,
    isPublic: true,
    label: defineMessage({
      defaultMessage: "Du plus récent au plus ancien",
    }),
  },
};

export const POSTS_SORT_LIST = Object.values(POSTS_SORTS);

export const POSTS_SORT_MESSAGE = defineMessage({
  id: "raiden.library.constants.posts.sort",
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} publication_date_asc {Date de publication croissante} publication_date_desc {Date de publication décroissante} updated_asc {Date de modification croissante} updated_desc {Date de modification décroissante} position_asc {Position croissante} position_desc {Position décroissante} other {{type}}}",
});

// Mode de sauvegarde (côté front)

export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT = "saveAsDraft";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW =
  "requestReview";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH =
  "programmatePublish";
export const POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH = "publish";

export const POSTS_FRONT_FORM_SUBMIT_MODES = {
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_SAVE_AS_DRAFT,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_REQUEST_REVIEW,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PROGRAMMATE_PUBLISH,
  },
  [POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH]: {
    id: POSTS_FRONT_FORM_SUBMIT_MODE_VALUE_PUBLISH,
  },
};

export const POSTS_FRONT_FORM_SUBMIT_MODE_LIST = Object.values(
  POSTS_FRONT_FORM_SUBMIT_MODES,
);

export const postsFrontFormSubmitModeMessage = defineMessage({
  id: "raiden.library.constants.posts.frontFormSubmitMode",
  defaultMessage:
    "{frontFormSubmitMode, select, saveAsDraft {Enregistrer comme brouillon} requestReview {Envoyer en modération} programmatePublish {Programmer la publication} publish {Publier immédiatement} other {{type}}}",
});

// visibility
export const POSTS_VISIBILITY_VALUE_PUBLIC = "public";
export const POSTS_VISIBILITY_VALUE_PRIVATE = "private";
export const POSTS_VISIBILITY_VALUE_LIMITED = "limited";

export const POSTS_VISIBILITIES = {
  [POSTS_VISIBILITY_VALUE_PUBLIC]: {
    id: POSTS_VISIBILITY_VALUE_PUBLIC,
  },
  [POSTS_VISIBILITY_VALUE_PRIVATE]: {
    id: POSTS_VISIBILITY_VALUE_PRIVATE,
  },
  [POSTS_VISIBILITY_VALUE_LIMITED]: {
    id: POSTS_VISIBILITY_VALUE_LIMITED,
  },
};

export const POSTS_VISIBILITY_LIST = Object.values(POSTS_VISIBILITIES);

export const postsVisibilityMessage = defineMessage({
  defaultMessage: `{visibility, select,
    public {publique}
    private {console}
    limited {espace propriétaire}
    other {{visibility}}
  }`,
});

export const postsVisibilityDescriptionMessage = defineMessage({
  defaultMessage:
    "{visibility, select, public {Sera visible sur le site internet} private {Sera visible uniquement par les administrateurs} limited {Sera visible uniquement par les propriétaires} other {{visibility}}}",
});
