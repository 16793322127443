// deps
import { useCallback } from "react";

// hooks
import useLocale from "./useLocale";

/**
 * @param {object} params
 * @param {import("../types/common/TranslatedField").TranslatedField | null} [params.obj]
 * @param {string} params.activeLocale
 * @param {string} params.defaultLocale
 * @returns {string | undefined}
 */
export function translate({ obj, activeLocale, defaultLocale }) {
  return obj ? obj[activeLocale] ?? obj[defaultLocale] ?? undefined : undefined;
}

/**
 * Hook qui retourne une fonction qui prend en paramètre un champ issue de l’API
 *   (de la forme { [lang]: string }) et qui va automatiquement
 *   afficher la valeur de la langue courante (si la valeur existe), ou à défaut la langue de base.
 *  Il est possible de forcer la locale en utilisant le paramètre forceLocale
 * @param {object} [options]
 * @param {string} [options.forceLocale]
 */
export default function useTranslate(options) {
  const { locale, defaultLocale } = useLocale();
  const activeLocale = options?.forceLocale ?? locale;

  const translateFn = useCallback(
    /**
     * @param {import("../types/common/TranslatedField").TranslatedField | null} [obj]
     * @returns {string | undefined}
     */
    function (obj) {
      return translate({ obj: obj, activeLocale, defaultLocale });
    },
    [activeLocale, defaultLocale],
  );

  return translateFn;
}
