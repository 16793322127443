import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * @param {any} value
 * @returns {string | undefined}
 */
export function getString(value) {
  switch (typeof value) {
    case "string":
      return value;
    case "number":
      return value.toString();
    default:
      return undefined;
  }
}

/**
 * @param {string} key
 * @returns {string | undefined}
 */
export function useRouterString(key) {
  const router = useRouter();

  const value = useMemo(() => {
    return getString(router.query[key]);
  }, [key, router.query]);

  return value;
}
