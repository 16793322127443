import { Center, Spinner } from "@chakra-ui/react";
import Splash from "@raiden/library-ui/components/Splash";
import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import { useIntl } from "react-intl";

function SplashNoResult() {
  const intl = useIntl();

  return (
    <Splash
      image="empty"
      title={intl.formatMessage({ defaultMessage: "Aucun résultat" })}
    />
  );
}

/**
 * @param {object} params
 * @param {import('@raiden/library-ui/types/Api/ApiError').ApiError} params.error
 */
function SplashError({ error }) {
  const intl = useIntl();

  return (
    <Splash
      image="error"
      title={intl.formatMessage({
        defaultMessage: "Une erreur est survenue",
      })}
      description={apiGetErrorDetail({ error })}
    />
  );
}

const loadingElement = (
  <Center>
    <Spinner size="lg" />
  </Center>
);

/** @type {import("@raiden/library-ui/components/DataHandler").DataHandlerContextValue} */
export const DATA_HANDLER_CONTEXT_VALUE = {
  loadingElement,
  SplashError,
  SplashNoResult,
};
