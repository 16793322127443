// deps
import { mode } from "@chakra-ui/theme-tools";

/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export default {
  parts: ["field", "icon"],
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      field: {
        color: colorScheme
          ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
          : mode("gray.800", "whiteAlpha.900")(props),
      },
      icon: {
        color: colorScheme
          ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
          : mode("gray.800", "whiteAlpha.900")(props),
      },
    };
  },
  variants: {
    outline(props) {
      return {
        field: {
          borderColor: mode("gray.200", "gray.600")(props),
        },
      };
    },
  },
};
