// constants
import { defineMessage } from "react-intl";
import { IoCheckmark, IoEllipsisHorizontalCircle } from "react-icons/io5";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

/* Tri */
export const AMENITIES_SORT_VALUE_CREATED_ASC = "created_asc";
export const AMENITIES_SORT_VALUE_CREATED_DESC = "created_desc";
export const AMENITIES_SORT_VALUE_NAME_ASC = "name_asc";
export const AMENITIES_SORT_VALUE_NAME_DESC = "name_desc";

export const amenitiesSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} name_asc {Nom croissant} name_desc {Nom décroissant} other {{sort}}}",
});

export const AMENITIES_SORTS = {
  [AMENITIES_SORT_VALUE_CREATED_ASC]: {
    id: AMENITIES_SORT_VALUE_CREATED_ASC,
  },
  [AMENITIES_SORT_VALUE_CREATED_DESC]: {
    id: AMENITIES_SORT_VALUE_CREATED_DESC,
  },
  [AMENITIES_SORT_VALUE_NAME_ASC]: {
    id: AMENITIES_SORT_VALUE_NAME_ASC,
  },
  [AMENITIES_SORT_VALUE_NAME_DESC]: {
    id: AMENITIES_SORT_VALUE_NAME_DESC,
  },
};

export const AMENITIES_SORT_LIST = Object.values(AMENITIES_SORTS);

export const AMENITIES_CATEGORY_VALUE_INSIDE = "inside";
export const AMENITIES_CATEGORY_VALUE_OUTSIDE = "outside";
export const AMENITIES_CATEGORY_VALUE_SERVICE = "service";
export const AMENITIES_CATEGORY_VALUE_PROXIMITY = "proximity";
export const AMENITIES_CATEGORY_VALUE_ACCESSIBILITY = "accessibility";
export const AMENITIES_CATEGORY_VALUE_BED = "bed";
export const AMENITIES_CATEGORY_VALUE_SANITARY = "sanitary";
export const AMENITIES_CATEGORY_VALUE_LABEL = "label";
export const AMENITIES_CATEGORY_VALUE_THEME = "theme";
export const AMENITIES_CATEGORY_VALUE_PARKING = "parking";

export const AMENITIES_CATEGORIES = {
  [AMENITIES_CATEGORY_VALUE_INSIDE]: {
    id: AMENITIES_CATEGORY_VALUE_INSIDE,
  },
  [AMENITIES_CATEGORY_VALUE_OUTSIDE]: {
    id: AMENITIES_CATEGORY_VALUE_OUTSIDE,
  },
  [AMENITIES_CATEGORY_VALUE_SERVICE]: {
    id: AMENITIES_CATEGORY_VALUE_SERVICE,
  },
  [AMENITIES_CATEGORY_VALUE_PROXIMITY]: {
    id: AMENITIES_CATEGORY_VALUE_PROXIMITY,
  },
  [AMENITIES_CATEGORY_VALUE_ACCESSIBILITY]: {
    id: AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
  },
  [AMENITIES_CATEGORY_VALUE_BED]: {
    id: AMENITIES_CATEGORY_VALUE_BED,
  },
  [AMENITIES_CATEGORY_VALUE_SANITARY]: {
    id: AMENITIES_CATEGORY_VALUE_SANITARY,
  },
  [AMENITIES_CATEGORY_VALUE_LABEL]: {
    id: AMENITIES_CATEGORY_VALUE_LABEL,
  },
  [AMENITIES_CATEGORY_VALUE_THEME]: {
    id: AMENITIES_CATEGORY_VALUE_THEME,
  },
  [AMENITIES_CATEGORY_VALUE_PARKING]: {
    id: AMENITIES_CATEGORY_VALUE_PARKING,
  },
};

export const AMENITIES_CATEGORY_LIST = Object.values(AMENITIES_CATEGORIES);

export const amenitiesCategoryMessage = defineMessage({
  defaultMessage: `{category, select,
    inside {Équipements intérieurs}
    outside {Équipements extérieurs}
    service {Services}
    proximity {A proximité}
    accessibility {Accessibilité}
    bed {Équipements lits}
    sanitary {Équipements sanitaires}
    label {Labels / partenaires}
    theme {Thèmes}
    parking {Parkings}
    other {{category}}
  }`,
});

export const AMENITIES_STATE_VALUE_PENDING = "pending";
export const AMENITIES_STATE_VALUE_PUBLISHED = "published";

export const AMENITIES_STATES = {
  [AMENITIES_STATE_VALUE_PENDING]: {
    id: AMENITIES_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    color: "orange",
    icon: IoEllipsisHorizontalCircle,
  },
  [AMENITIES_STATE_VALUE_PUBLISHED]: {
    id: AMENITIES_STATE_VALUE_PUBLISHED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoCheckmark,
  },
};

export const AMENITIES_STATES_LIST = Object.values(AMENITIES_STATES);

export const amenitiesStateMessage = defineMessage({
  defaultMessage: `{state, select,
    pending {En attente}
    published {Activée}
    other {{state}}
  }`,
});

export const amenitiesStateUpdateVerbMessage = defineMessage({
  defaultMessage:
    "{state, select, published {activer} pending {mettre en attente} other {state}}",
});
export const amenitiesStateUpdateMessage = defineMessage({
  defaultMessage:
    "{state, select, published {activée} pending {mise en attente} other {state}}",
});

export const amenitiesSearchTabMessage = defineMessage({
  defaultMessage:
    "{tab, select, all {Tous} pending {En attente} published {Activé} trash {Corbeille} other {{tab}}}",
  description: "tab",
});

export const AMENITIES_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};

export const AMENITIES_DISTANCE_UNIT_VALUE_KM = "km";
export const AMENITIES_DISTANCE_UNIT_VALUE_M = "m";

export const AMENITIES_DISTANCE_UNITS = {
  [AMENITIES_DISTANCE_UNIT_VALUE_KM]: {
    id: AMENITIES_DISTANCE_UNIT_VALUE_KM,
  },
  [AMENITIES_DISTANCE_UNIT_VALUE_M]: {
    id: AMENITIES_DISTANCE_UNIT_VALUE_M,
  },
};

export const AMENITIES_DISTANCE_UNIT_LIST = Object.values(
  AMENITIES_DISTANCE_UNITS,
);

export const amenitiesDistanceUnitMessage = defineMessage({
  defaultMessage: `{unit, select,
    km {km}
    m {m}
    other {{unit}}
  }`,
});

export const AMENITIES_IS_SEARCHABLE_VALUE_YES = "yes";
export const AMENITIES_IS_SEARCHABLE_VALUE_NO = "no";

export const AMENITIES_IS_SEARCHABLE = {
  [AMENITIES_IS_SEARCHABLE_VALUE_YES]: {
    id: AMENITIES_IS_SEARCHABLE_VALUE_YES,
  },
  [AMENITIES_IS_SEARCHABLE_VALUE_NO]: {
    id: AMENITIES_IS_SEARCHABLE_VALUE_NO,
  },
};

export const AMENITIES_IS_SEARCHABLE_LIST = Object.values(
  AMENITIES_IS_SEARCHABLE,
);

export const amenitiesIsSearchableMessage = defineMessage({
  defaultMessage: `{isSearchable, select,
    yes {Visible sur le SN}
    no {Non visible sur le SN}
    other {{isSearchable}}
  }`,
});
