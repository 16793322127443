// deps
import {
  parseDate,
  parseDateTime,
  CalendarDateTime,
} from "@internationalized/date";
import dayjs from "dayjs";

// components
import { forwardRef } from "react";
import Input from "./Input";

/**
 * Format a string to a date object.
 * @param {object} param0
 * @param {string | null} [param0.value]
 * @param {string} [param0.granularity]
 * @returns {import("@internationalized/date").CalendarDateTime|import("@internationalized/date").CalendarDate | undefined}
 */
function fromStringToDateObject({ value, granularity }) {
  if (!value) {
    return undefined;
  }

  try {
    switch (granularity) {
      case "day":
      case "month":
        return parseDate(value);
      default:
        return parseDateTime(value);
    }
  } catch (e) {
    console.warn(e);
  }
  return undefined;
}

/**
 * Format a date object to a string.
 * @param {object} param0
 * @param {import("@internationalized/date").CalendarDateTime | import("@internationalized/date").CalendarDate} param0.selectedDate
 * @param {"day" | "hour"| "minute"| "second"} [param0.granularity]
 * @param {string | null} [param0.value]
 * @returns {string | ""}
 */
function fromDateObjectToString({ selectedDate, value, granularity }) {
  if (!selectedDate) {
    return "";
  }
  // set the time to the current time
  if (granularity !== "day" && !value) {
    const date = dayjs(selectedDate.toString(), "YYYY-MM-DD");
    const today = dayjs();
    return new CalendarDateTime(
      date.get("year"),
      date.get("month") + 1,
      date.get("date"),
      today.get("hour"),
      today.get("minute"),
      today.get("second"),
    ).toString();
  }

  return selectedDate.toString();
}

const DatePicker = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {string | null} [value]
   * @property {string} [placeholder]
   * @property {"day" | "hour"| "minute"| "second"} [granularity]
   * @property {string} [minValue]
   * @property {string} [maxValue]
   * @property {boolean} [shouldCloseOnSelect]
   * @property {boolean} [shouldCloseOnDaySelect]
   * @property {boolean} [shouldRenderClearButton]
   * @property {boolean} [shouldRenderTodayButton]
   * @property {boolean} [shouldAutoOpenPicker]
   * @property {boolean} [isDisabled]
   * @property {boolean} [enableKeyboardInput]
   * @property {(event: any) => void} onChange
   */

  /**
   * @param {Props} props
   * @param {import("react").ForwardedRef<HTMLInputElement>} ref
   * @returns {import("react").FunctionComponentElement<Props>}
   */
  function DatePicker(props, ref) {
    const {
      name,
      value,
      granularity = "day",
      shouldCloseOnSelect = false,
      shouldCloseOnDaySelect = true,
      shouldAutoOpenPicker = true,
      enableKeyboardInput = false,
      shouldRenderClearButton = true,
      shouldRenderTodayButton = true,
      onChange,
      minValue,
      maxValue,
      ...otherProps
    } = props;

    function handleChange(selectedDate) {
      onChange({
        target: {
          name,
          value: selectedDate
            ? fromDateObjectToString({ selectedDate, value, granularity })
            : "",
        },
      });
    }

    return (
      <Input
        {...otherProps}
        {...(minValue && {
          minValue: fromStringToDateObject({ value: minValue, granularity }),
        })}
        {...(maxValue && {
          maxValue: fromStringToDateObject({ value: maxValue, granularity }),
        })}
        shouldRenderTodayButton={shouldRenderTodayButton}
        shouldRenderClearButton={shouldRenderClearButton}
        enableKeyboardInput={enableKeyboardInput}
        shouldAutoOpenPicker={shouldAutoOpenPicker}
        shouldCloseOnDaySelect={shouldCloseOnDaySelect}
        shouldCloseOnSelect={shouldCloseOnSelect}
        name={name}
        ref={ref}
        granularity={granularity}
        aria-label="Date picker"
        value={fromStringToDateObject({ value, granularity })}
        onChange={handleChange}
      />
    );
  },
);

export default DatePicker;
