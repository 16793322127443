import { createContext, useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { FormAutoSubmit } from "../../components/Form/AutoSubmit";

/**
 * @template {import("react-hook-form").FieldValues} [TFieldsValues=any]
 * @template {any} [TResponse=any]
 * @typedef {object} SearchInfiniteContextValue
 * @property {import("swr/infinite").SWRInfiniteResponse<TResponse>} swrInfiniteResponse
 * @property {() => void} reset
 * @property {string[]} appliedFilters
 * @property {() => void} submit
 * @property {import("react").FormEventHandler<HTMLDivElement>} handleSubmit
 * @property {import("react-hook-form").UseFormReturn<TFieldsValues>} form
 * @property {boolean} autoSubmit
 * @property {TFieldsValues} submittedFields
 * @property {import("swr").KeyedMutator<any>} mutate
 * @property {(size: number | ((_size: number) => number)) => Promise<any[] | undefined>} setSize
 */

/** @type {Partial<SearchInfiniteContextValue>} */
const SEARCH_INFINITE_CONTEXT_DEFAULT_VALUE = {
  swrInfiniteResponse: undefined,
  reset: () => {},
  appliedFilters: [],
  submit: () => {},
  form: undefined,
  autoSubmit: false,
  submittedFields: {},
};

export const SearchInfiniteContext = createContext(
  /** @type {SearchInfiniteContextValue} */ (
    SEARCH_INFINITE_CONTEXT_DEFAULT_VALUE
  ),
);

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props & SearchInfiniteContextValue} props
 */
function SearchInfiniteProvider({
  form,
  appliedFilters,
  reset,
  submit,
  swrInfiniteResponse,
  autoSubmit,
  submittedFields,
  mutate,
  setSize,
  children,
}) {
  const handleSubmit = useCallback(
    /** @type {import("react").FormEventHandler<HTMLDivElement>} */
    (e) => {
      e.preventDefault();
      submit();
    },
    [submit],
  );

  const value = useMemo(() => {
    /** @type {SearchInfiniteContextValue} */
    const value = {
      appliedFilters,
      form,
      reset,
      submit,
      handleSubmit,
      swrInfiniteResponse,
      autoSubmit,
      submittedFields,
      mutate,
      setSize,
    };
    return value;
  }, [
    appliedFilters,
    autoSubmit,
    form,
    handleSubmit,
    mutate,
    reset,
    setSize,
    submit,
    submittedFields,
    swrInfiniteResponse,
  ]);

  return (
    <FormProvider {...form}>
      <SearchInfiniteContext.Provider value={value}>
        <FormAutoSubmit onSubmit={submit} isDisabled={!autoSubmit} />

        {children}
      </SearchInfiniteContext.Provider>
    </FormProvider>
  );
}

export default SearchInfiniteProvider;
