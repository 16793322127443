// deps
import { getColor, mode } from "@chakra-ui/theme-tools";

function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode("red.500", "red.300")(props),
  };
}

/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export default {
  variants: {
    filled(props) {
      const { theme } = props;

      return {
        field: {
          border: "0.125rem solid",
          borderColor: "transparent",
          color: mode("black", "white")(props),
          bg: mode("white", "black")(props),
          _hover: {
            bg: mode("gray.200", "whiteAlpha.100")(props),
          },
          _readOnly: {
            boxShadow: "none !important",
            userSelect: "all",
          },
          _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
          },
          _invalid: {
            borderColor: getColor(theme, mode("red.500", "red.300")(props)),
          },
          _focus: {
            bg: "transparent",
            borderColor: getColor(theme, mode("blue.500", "blue.300")(props)),
          },
        },
        addon: {
          border: "0.125rem solid",
          borderColor: "transparent",
          bg: mode("gray.100", "whiteAlpha.50")(props),
        },
      };
    },
    outline(props) {
      return {
        field: {
          borderColor: mode("gray.200", "gray.600")(props),
        },
      };
    },
    outline2(props) {
      const { theme } = props;
      const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

      return {
        field: {
          border: "1px solid",
          borderColor: mode("gray.200", "gray.600")(props),
          bg: "inherit",
          _hover: {
            borderColor: mode("gray.300", "whiteAlpha.400")(props),
          },
          _readOnly: {
            boxShadow: "none !important",
            userSelect: "all",
          },
          _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
          },
          _invalid: {
            borderColor: getColor(theme, ec),
            boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
          },
          _focus: {
            zIndex: 1,
            borderColor: getColor(theme, fc),
            boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
          },
        },
        addon: {
          border: "1px solid",
          borderColor: mode("inherit", "whiteAlpha.50")(props),
          bg: mode("gray.100", "whiteAlpha.300")(props),
        },
      };
    },
  },
};
